
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import DetailFR from '../../component/detailMobileFR'
import {withRouter} from 'react-router-dom'
import { products } from '../../component/products';
import { hideMask } from '../../component/cartMobile';
import { changeUrlMobile } from '../../common/changeUrl';
 
class PageCream extends Component {

  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1200,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 900);
  }

  componentDidMount(){
    if(!$(".collection").hasClass('active')){
        $(".collection").addClass('active')
    }
    $(".exclusive").removeClass('active')
    $(".discover").removeClass('active')
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
  }

  render() {
      return (
          <div className="container">
              <DetailFR product={products.FR.b}></DetailFR>
          </div>
      );
  }
}
 
export default withRouter(PageCream);