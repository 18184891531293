
import {Component} from 'react';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { accept, checkCookie, changeUrlMobile} from '../common/changeUrl'
import logo from '../common/images/logo.png';
import { products } from './products';

import menuIcon from '../common/images/menu.png'
import cartIcon from '../common/images/cart.png'

import { hideMenuMobile } from '../common/changeUrl';

const pageText = {
    navItmes: ["collections","discover","exclusive","cart","fr"],
  }
  

const showMask = () => {
  if ($(".nav-menu").hasClass("is-open")){
    jQuery(".header-menu").animate({
      top: '-240px',
      opacity: 0
    },300,"easeInOutExpo",function(){
      $(".header-menu").css("top",'-240px')
      $(".header-menu").css("opacity",0)
    })
    $(".nav-menu").removeClass("is-open")
  }
  $(".cart-mask").fadeIn(500)
  jQuery(".cart-wrapper,.cart-header,.cart-checkout").animate({
    right: '0'
  },600,"easeInCubic",function(){
      $(".cart-wrapper,.cart-header,.cart-checkout").css("right",0)
  })
  
  $(`.product-item-chevron, .product-list`).removeClass("active")
}

var totals = 0
var carts = {"total": 0}
var cartsFinal = Object.values(products.EN)

const showMenu = () => {
  $(".product-list").removeClass("active")
  if ($(".nav-menu").hasClass("is-open")){
    jQuery(".header-menu").animate({
      top: '-240px',
      opacity: 0
    },500,"easeInOutExpo",function(){
      $(".header-menu").css("top",'-240px')
      $(".header-menu").css("opacity",0)
    })
    $(".nav-menu").removeClass("is-open")
    $(".header-mask").fadeOut(500)
  } else {
    $(".header-mask").fadeIn(500)
    jQuery(".header-menu").animate({
      top: '80px',
      opacity: 1
    },500,"easeInOutExpo",function(){
      $(".header-menu").css("top",'80px')
      $(".header-menu").css("opacity",1)
    })
    $(".nav-menu").addClass("is-open")
  }
  $(`.product-item-chevron, .product-list`).removeClass("active")
  $(".tac-menu-mask").fadeOut(500)
  $(".collection-menu-mask").fadeOut(500)

  if($(".product-item-chevron").hasClass("is-open")){
    jQuery(".form-options").animate({
        top: '-130px'
      },300,"easeInCubic",function(){
        $(".form-options").css("top","-130px")
      })
      $(".product-item-chevron").toggleClass("is-open");
}
  
}

const changeLang = () => {
  var currentlang = JSON.parse(sessionStorage.getItem("cart"))
  if (currentlang.lang === 'EN'){
    currentlang.lang = "FR"
    sessionStorage.setItem("cart",JSON.stringify(currentlang))
    window.location.reload();
  }
}

class Header extends Component {

  componentWillMount(){
    if(sessionStorage.getItem("cart") === null){
      var cartItems = []
      cartsFinal.map((item) => {
        cartItems.push(
          {
            id: item.id,
            num: 0
          }
        )
      })
      carts = {cartItems,"total" : 0,"lang":"EN"}
      sessionStorage.setItem("cart",JSON.stringify(carts))
  } 
    if (sessionStorage.getItem("cart") != null){
      totals = JSON.parse(sessionStorage.getItem("cart")).total
    }
  }
    
    componentDidMount() {
      window.addEventListener("setItemEvent", (e) => {
        console.log(e)
          if ((e.key = "cart")) {
              var _this = sessionStorage.getItem("cart");

              if (_this !== e.newValue) {
                  if (e.newValue) {
                      this.setState({
                          show: e.newValue
                      })
                  }
                  totals = JSON.parse(e.newValue).total
              }
          }
      })
    }

    componentWillUnmount(){

    }
 
  render() {
    return (
      <div>
        <header className="App-header">
          <Link to="/" className="nav-item" onClick={()=>{changeUrlMobile("home")}}><img src={logo} className="App-logo nihplodfont-light"  /></Link>
          <div className="title-lang">
              <a href="javascript:void(0)" className="nav-item nav-menu" onClick={() => showMenu()}><img src={menuIcon}></img></a>
              <a href="javascript:void(0)" className="nav-item" onClick={() => showMask()}><img src={cartIcon}></img><div className='cart'>{sessionStorage.getItem("cart") === null ? 0 : totals}</div></a>
          </div>
        </header>
        <div className='header-menu'>
          <Link to="/collection" className='menu-wrapper' onClick={()=>{changeUrlMobile("collection")}}><span className="nav-item collection" >{pageText.navItmes[0]}</span></Link>
          <Link to="/discover" className='menu-wrapper' onClick={()=>{changeUrlMobile("discover")}}><span className="nav-item discover">{pageText.navItmes[1]}</span></Link>
          <Link to="/exclusive" className='menu-wrapper' onClick={()=>{changeUrlMobile("exclusive")}}><span  className="nav-item exclusive">{pageText.navItmes[2]}</span></Link>
          <a href="javascript:void(0)" className="menu-wrapper" onClick={() => changeLang()}><span  className="nav-item language">{pageText.navItmes[4]}</span></a>
        </div>
        <div className='header-mask' onClick={() => hideMenuMobile()}></div>
      </div>
    );
  }
}
 
export default withRouter(Header);
