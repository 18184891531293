import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import { accept, checkCookie, changeUrl,checkPolicy} from './common/changeUrl';
import App from './App';
import AppMobile from './AppMobile';

import './index.less';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const AppRender = () => (
  <React.StrictMode>
    <Suspense fallback={<div style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>}>
      { window.isMobile && <AppMobile /> || <App /> }
    </Suspense>
  </React.StrictMode>
);

setTimeout(() => {
  ReactDOM.render(
    <AppRender />,
    document.getElementById('root')
  );
  window.addEventListener('resize', () => {
    ReactDOM.render(
      <AppRender />,
      document.getElementById('root')
    );
  });
}, 222);
