
import './index.less';
import {Component} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { accept, checkCookie, changeUrlMobile} from '../../common/changeUrl'
import { upperLaundry } from '../../common/utils';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay , A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css/bundle';

import vogue from '../../common/images/home/vogue.png'
import elle from '../../common/images/home/elle.png'
import bazaar from '../../common/images/home/bazaar.png'
import quote from '../../common/images/home/quote.png'

import popular1 from '../../common/images/collection/giftbox6.jpg'
import popular2 from '../../common/images/collection/cream5.jpg'
import popular3 from '../../common/images/collection/sunscreen.jpg'

import banner2 from '../../common/images/home/banner3.jpg'
import banner4 from '../../common/images/home/banner2.jpg'
import { hideMask } from '../../component/cartMobile';

const pageText = {
  banner: {
    title: ["Elevate Your","Skincare Ritual", "SKINCARE", "EXPERIENCE","Indulge in","Luxurious Hydration","Discover our body skincare collection for ultimate rejuvenation"],
    button: `shop now`
  },
  popular: ["LE PLUS","POPULAIRE"],
  magazine: {
    vogue: `
    <span class="upper-size">NIHPLOD</span>'s step by step facial that focuses on brightening and diminishing pigmentation whilst balancing the skin.
    `,
    elle:`
    <span class="upper-size none-logo">E</span>lle honors <span class="upper-size">NIHPLOD</span> with its second award. <span class="upper-size none-logo">O</span>ur <span class="upper-size none-logo">F</span>ace <span class="upper-size none-logo">C</span>ream has made the cut in the world's most esteemed grooming routine as <span class="upper-size none-logo">B</span>est “<span class="upper-size none-logo">S</span>plurge” <span class="upper-size none-logo">M</span>oisturizer.
    `,
    bazaar: `
    <span class="upper-size none-logo">W</span>ith the help of new generation liposome technology, <span class="upper-size">NIHPLOD</span> solves the problem that skin can't really absorb skincare products.
    `,
  },
  banner2: {
    title: ["SOINS DE","LA PEAU","MINIMALISTES"],
    content: [
      `<span class="upper-size-content none-logo">N</span>otre objectif est de rendre votre routine de soins de la peau plus facile et plus efficace.`,
    `<span class="upper-size-content none-logo">A</span>ucun choix compliqué n'est nécessaire, ce qui rend le tout plus simple et agréable.`],
    button: `<span class="upper-size-content none-logo">D</span>écouvrir plus`
  },
  banner4: {
    title: ["NOTRE","HISTOIRE"],
    content: [
      `<p>La peau du dauphin a la capacité magique de se renouveler toutes les deux heures. Notre source d’inspiration est cet instinct animal de ‘l’inversion du temps’.</p>
      <p>La peau sera confrontée à divers problèmes causés par le temps, l’environment, la pression au travail et les mauvaises habitudes.</p>
      <p>Nous avons ainsi inversé les lettres du mot anglais "DOLPHIN", et avons obtenu NIHPLOD.</p>`
    ],
    button: `<span class="upper-size-content none-logo">D</span>écouvrir plus`
  },
  mostLoved: {
    title: "LES PLUS AIMÉS",
  }

}

const mouseOver = (e) => {
  $(`.maga-item-front.${e}`).fadeOut(200)
  $(`.maga-item-back.${e}`).fadeIn(200)
}

const mouseLeave = (e) => {
  $(`.maga-item-front.${e}`).fadeIn(200)
  $(`.maga-item-back.${e}`).fadeOut(200)
}

class PageHome extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1200,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 900);
  }

  componentDidMount(){
    $(".collection").removeClass('active')
    $(".discover").removeClass('active')
    $(".exclusive").removeClass('active')
    setTimeout(() => {
      $(".homeswiper").show(300)
    }, 1200);
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
  }

  componentWillUnmount(){
    
    $(".homeswiper").hide()
  }


  render (){
    return (
      <div className="container mobile">
        <div className='banner'>
          <Swiper
              modules={[Autoplay ]}
              slidesPerView={1}
              speed={3000}
              autoplay={{delay: 3000}}
              loop={true}
              className="bannerswiper homeswiper"
            >
              <SwiperSlide>
                <div className='banner-slide'>
                <div className='slogan'>
                    <p>{pageText.banner.title[0]}</p>
                    <p>{pageText.banner.title[1]}</p>
                    <Link to="/collection" className="find-out-more" onClick={() => {changeUrlMobile("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
                </div></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='banner-slide two'>
                <div className='slogan'>
                    <p>{pageText.banner.title[4]}</p>
                    <p>{pageText.banner.title[5]}</p>
                    <p className='banner-context'>{pageText.banner.title[6]}</p>
                    <Link to="/collection" className="find-out-more" onClick={() => {changeUrlMobile("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
                </div></div>
              </SwiperSlide>
            </Swiper>
        </div>
        <div className='most-loved'>
          <div className='most-loved-title'>{pageText.mostLoved.title}</div>
          </div>
        <Swiper
            modules={[Navigation, A11y,Autoplay ]}
            slidesPerView={1}
            speed={1000}
            autoplay={{delay: 3000}}
            className="most-popular homeswiper"
            loop={true}
          >
          <SwiperSlide>
            <Link to={{pathname: "/face-cream" ,source: 'h'}} className='most-popular-wrapper' onClick={() => changeUrlMobile('cream')}>
              <div className='most-popular-item'>
                <img src={popular2}></img>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={{pathname: "/sunscreen" ,source: 'h'}} className='most-popular-wrapper' onClick={() => changeUrlMobile('sunscreen')}>
              <div className='most-popular-item'>
                <img src={popular3}></img>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
          <Link to={{pathname: "/gift-box-series" ,source: 'h'}} className='most-popular-wrapper' onClick={() => changeUrlMobile('gift-box-series')}>
              <div className='most-popular-item'>
                <img src={popular1}></img>
              </div>
            </Link>
          </SwiperSlide>
          </Swiper>


        <div className='skin-care-journey'>
          <div className='skin-care-journey-content'>
          <div className='content-title'>
              <p>{pageText.banner2.title[0]}</p>
              <p>{pageText.banner2.title[1]}</p>
            </div>
            <div className='content-text'>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner2.content[0] }}></p>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner2.content[1] }}></p>
            </div>
            <Link to="/discover" className="discover-more" onClick={() => {changeUrlMobile("discover")}} dangerouslySetInnerHTML={{ __html: pageText.banner2.button }}></Link>
          </div>
          <div className='banner2'>
            <img src={banner2}></img>
          </div>
        </div>

        
        <Swiper
            modules={[Navigation, A11y,Autoplay ]}
            slidesPerView={1}
            speed={1000}
            autoplay={{delay: 3000}}
            className="magazines homeswiper"
            loop={true}
          >
          <SwiperSlide>
            <div className='maga-item-m' onMouseOver={() => {mouseOver('vogue')}} onMouseLeave={() => {mouseLeave('vogue')}}>
              <div className='maga-item-front-m vogue'>
                <img src={vogue}></img>
              </div>
              <div className='maga-item-back-m vogue'>
                <div className='quote-icon'>
                  <img src={quote}></img>
                </div>
                <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.vogue }}>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='maga-item-m' onMouseOver={() => {mouseOver('elle')}} onMouseLeave={() => {mouseLeave('elle')}}>
              <div className='maga-item-front-m elle'>
                <img src={elle}></img>
              </div>
              <div className='maga-item-back-m elle'>
              < div className='quote-icon'>
                  <img src={quote}></img>
                </div>
                <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.elle }}></div>
              </div>
            </div>

          </SwiperSlide>
          <SwiperSlide>
            <div className='maga-item-m' onMouseOver={() => {mouseOver('bazaar')}} onMouseLeave={() => {mouseLeave('bazaar')}}>
              <div className='maga-item-front-m bazaar'>
                <img src={bazaar}></img>
              </div>
              <div className='maga-item-back-m bazaar'>
                <div className='quote-icon'>
                  <img src={quote}></img>
                </div>
                <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.bazaar }}></div>
              </div>
            </div>

          </SwiperSlide>
        </Swiper>
        
        <div className='content-section'>
          <div className='banner4'>
            <img src={banner4}></img>
          </div>
          <div className='content-section-content left'>
            <div className='content-title'>
              <p>{pageText.banner4.title[0]}</p>
              <p>{pageText.banner4.title[1]}</p>
            </div>
            <div className='content-text'>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner4.content[0] }}></p>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner4.content[1] }}></p>
            </div>
            <Link to="/discover" className="discover-more" onClick={() => {changeUrlMobile("discover")}} dangerouslySetInnerHTML={{ __html: pageText.banner4.button }}></Link>
          </div>
        </div>

      </div>
      
    );
  }
}

export default PageHome;
