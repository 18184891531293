
import coverA from '../common/images/collection/cleanser1.jpg';
import coverB from '../common/images/collection/cream4.jpg';
import coverC from '../common/images/collection/serum3.jpg';
import coverD from '../common/images/collection/sunscreen.jpg';
import coverE from '../common/images/collection/facemask1.jpg';
import coverF from '../common/images/collection/facescrub2.jpg';

import cream2 from '../common/images/cream/facecream1.jpg'
import cream1 from '../common/images/cream/p1.png'

import cleanser2 from '../common/images/collection/cleanser2.jpg'
import mask2 from '../common/images/collection/facemask2.jpg'
import maskb from '../common/images/collection/facemask4.jpg'
import scrub2 from '../common/images/collection/facescrub3.jpg'
import serum2 from '../common/images/collection/serum2.jpg'
import sunscreen2 from '../common/images/collection/sunscreen2.jpg'

import scrub3 from '../common/images/collection/scrub2.jpg'
import scrub4 from '../common/images/collection/facescrub4.jpg'
import mask3 from '../common/images/collection/mask2.jpg'
import cleanser3 from '../common/images/collection/cleanser3.jpg'
import sunscreen3 from '../common/images/collection/sunscreen3.jpg'
import sunscreen4 from '../common/images/collection/sunscreen4.jpg'
import cream3 from '../common/images/collection/cream3.jpg'
import serum3 from '../common/images/collection/serum4.jpg'
import cream4 from '../common/images/collection/cream4.jpg'
import cream5 from '../common/images/collection/cream5.jpg'

import handcream1 from '../common/images/collection/handcream1.jpg'
import handcream2 from '../common/images/collection/handcream2.jpg'
import handcream3 from '../common/images/collection/handcream3.jpg'

import bodylotion3 from '../common/images/collection/bodylotion3.jpg'
import bodylotion1 from '../common/images/collection/bodylotion1.jpg'
import bodylotion2 from '../common/images/collection/bodylotion2.jpg'

import giftbox1 from '../common/images/collection/giftbox1.jpg'
import giftbox2 from '../common/images/collection/giftbox2.png'
import giftbox3 from '../common/images/collection/giftbox3.jpg'
import giftbox4 from '../common/images/collection/giftbox4.jpg'
import giftbox5 from '../common/images/collection/giftbox5.jpg'
import giftbox6 from '../common/images/collection/giftbox6.jpg'
import giftboxall from '../common/images/collection/giftboxall.jpg'

import creamR1 from '../common/images/home/facecream1.jpg'
import serumR1 from '../common/images/home/serum1.jpg'
import scrubR1 from '../common/images/home/facescrub1.jpg'

import lf1 from '../common/images/mask/facemask1.jpg'
import lf2 from '../common/images/mask/lf2.jpg'

import homecream2 from '../common/images/home/homecream2.jpg'
import homecream3 from '../common/images/home/homecream3.jpg'
import homecream4 from '../common/images/home/homecream4.jpg'

import homesun2 from '../common/images/home/homesun2.jpg'
import homesun3 from '../common/images/home/homesun3.jpg'
import homesun4 from '../common/images/home/homesun4.jpg'

import homebox2 from '../common/images/home/homebox2.jpg'
import homebox3 from '../common/images/home/homebox3.jpg'
import homebox4 from '../common/images/home/homebox4.jpg'

export const products = {
    notes: {
      EN: {
        one: `
        <p>*Due to Cosmetics Europe's policy changes, some of the new 2025 products packaging and ingredients have been slightly modified. Online orders placed before 09/22 will be shipped randomly depending on the availability of the previous version of the product.</p>
        `
      },
      FR: {
        one: `
        <p>*En raison d'un changement de politique de Cosmetics Europe, l'emballage et les ingrédients de certains des nouveaux produits 2025 ont été légèrement modifiés. Les commandes en ligne passées avant le 09/22 seront expédiées de manière aléatoire en fonction de la disponibilité de la précédente version du produit.</p>
        `
      },
    },
    EN: {
        b: {
            id: "NBSH921036",
            name: "FOAM CLEANSER",
            short: "cleanser",
            specName: "Foam Cleanser",
            specVol: "3.4 OZ / 100 ML",
            specPrice: 105,
            specIntro: "3.4 OZ / 100 ML",
            specCover: coverA,
            url: "foam-cleanser",
            details: [coverA,cleanser3,cleanser2],
            benefit: `
              <p>Foam cleanser suitable for all skin types, which can remove dirt and cosmetics carefully without depriving the skin of its natural oils and fats.</p>
              <p>After a deep cleansing, the skin is radiant with ceramide, blackberry leaf extracts and other effective factors. It has been clinically proved that it can：
              </p>
              
              <p><span class="text-radio"></span>Gently and effectively remove impurities and dead skin cells<br/><span class="text-radio"></span>Provide strong antioxidant protection against redness and irritation<br/><span class="text-radio"></span>Help maintain the skin's natural moisture balance, making the skin smooth, soft and fresh</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              
              <p>Water/Aqua/Eau, Potassium Cocoyl Glycinate, Glycerin, Cocamidopropyl Betaine, Disodium Cocoamphodiacetate, Sodium Chloride, Phenoxyethanol, Chamaecyparis Obtusa Water, Paeonia Suffruticosa Flower Water, Caprylyl Glycol, Niacinamide, Panthenol, Ethylhexylglycerin, Xanthan Gum, Lactic Acid, Disodium EDTA, Butylene Glycol, Hydroxyacetophenone, Sodium Hyaluronate, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>Apply one to three pumps on the dry skin, gently massage to form a creamy foam, then rinse with warm water.</p>
              
              <p>For an in-depth cleansing, please maintain the product for 30 seconds as a mini-mask of foam before rinsing.</p>
              <div class='end-line'></div>
            `,
          },
          e: {
            id: "NBSH921033",
            name: "FACE CREAM",
            short: "cream",
            specName: "Face Cream",
            specVol: "1.7 OZ / 50 ML",
            specPrice: 345,
            specIntro: "1.7 OZ / 50 ML",
            specHome:"50ml - 299€",
            specCover: cream5,
            coverPos:"50% 100%",
            url: "face-cream",
            details: [cream5,cream3,cream2],
            homeUse: [cream5,homecream2,homecream3,homecream4],
            benefit: `
              <p>A precious facial cream that keeps your skin hydrated and repairs damaged skin.<br/>
              Its bioactive complex helps protect the skin from environmental damage and repair short-term skin damage. Its multiple moisturizing and repairing factors help prevent dryness.</p>
              <p>Through liposome technology, the moisturizing and repairing ingredients can penetrate effectively into the skin. It has been clinically proved that it can effectively improve bad conditions linked to the skin's long-term improper maintenance, as well as postoperative redness, swelling, or darkening. After 2-4 weeks of use, your face will present a beautiful, elastic and smooth skin, and lay the foundation for a younger appearance.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Glycerin, Dimethicone, Isononyl Isononanoate, Hydrogenated Ethylhexyl Olivate, Cyclopentasiloxane, Butyrospermum Parkii (Shea) Butter, Panthenol, Polyglyceryl-3 Methylglucose Distearate, Behenyl Alcohol, Glyceryl Stearate Citrate, Hexyldecanol, Tocopheryl Acetate, Sodium Polyacrylate, Hydrogenated Olive Oil Unsaponifiables, Dimethicone/Vinyl Dimethicone Crosspolymer, Octanediol, Hydroxypropyl Tetrahydropyrantriol, Ethylhexyl Stearate, Olea Europaea (Olive) Fruit Oil, Ethylhexylglycerin, Avena Sativa (Oat) Kernel Oil, Bisabolol, Butylene Glycol, Arginine, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Trimethylsiloxysilicate, 1,2-Hexanediol, Sodium Hyaluronate, Ceramide NP, Trideceth-6, Citrus Grandis (Grapefruit) Seed Extract, Cetylhydroxyproline Palmitamide, Stearic Acid, Farnesol, Disodium EDTA, Hydrogenated Lecithin, Phytosteryl Oleate, Brassica Campestris (Rapeseed) Sterols, Artemisia Princeps Leaf Extract, Phytosterols, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>After a deep cleaning of the skin, put some face cream on your fingertips and gently rub them on your face. Gently massage around your cheeks and eyes in small circles. Please be careful when massaging around your eyes in order to avoid any contact with your eyes. Please maintain the cream for at least 10 minutes before using follow-up products depending on your personal situation.</p>
              
              <p>* We recommend using this product under the professional guidance of our partner Spa salons or distributors.</p>
              <div class='end-line'></div>
            `,
          },
          c: {
            id: "NBSH921034",
            name: "SERUM",
            short: "serum",
            specName: "Serum",
            specVol: "1 OZ / 30 ML",
            specPrice: 550,
            specIntro: "1 OZ / 30 ML",
            specCover: coverC,
            specHome:"30ml - 499€",
            url: "serum",
            details: [coverC,serum3,serum2],
            homeUse: serumR1,
            benefit: `
              <p>Multiple kinds of valuable natural extracts were carefully used to create this luxurious essence.<br/>The serum lets real nutrition and repair factors get into the skin to help the skin stay firm and restore its health.</p>
              <p>Through liposome technology, the serum can effectively delay the natural aging of the skin. Indeed, it has been clinically proved that it can make skin look younger and more compact. The core combination formula of a variety of natural extracts can significantly improve the relaxation, fine lines and uneven pigmentation of the face (including around the eyes) and neck, and help to create a more delicate appearance.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Butylene Glycol, Trehalose, Bis-PEG-18 Methyl Ether Dimethyl Silane, Niacinamide, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, Hydroxyacetophenone, Hydroxyethylpiperazine Ethane Sulfonic Acid, Glycerin, Arginine, Bifida Ferment Lysate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Hydroxypropyl Tetrahydropyrantriol, Troxerutin, Tremella Fuciformis Sporocarp Extract, Diphenyl Dimethicone, Triethylhexanoin, Sodium Benzoate, Phenoxyethanol, Potassium Sorbate, Polyglyceryl-10 Myristate, Disodium EDTA, Ethylhexylglycerin, Pentylene Glycol, Palmitoyl Tripeptide-5, Tapioca Starch, Tocopherol, Galactomyces Ferment Filtrate, Oligopeptide-1, Prunus Yedoensis Leaf Extract, PVP, Arginine/Lysine Polypeptide, Fullerenes, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>After a deep cleaning of the skin, put some serum on your fingertips and gently rub them on your face.</p>
              <p>Gently massage your cheeks, eyes and neck in small circles. Please be careful when massaging around your eyes in order to avoid any contact with your eyes. Please maintain the serum for at least 10 minutes before using follow-up products depending on your personal situation.</p>
              
              <p>* We recommend using this product under the professional guidance of our partner Spa salons or distributors.</p>
              <div class='end-line'></div>
            `,
          },
          d: {
            id: "NBSH921037",
            name: "SUNSCREEN",
            short: "sunscreen",
            specName: "Sunscreen",
            specVol: "1.35 OZ / 40 ML",
            specPrice: 165,
            specIntro: "1.35 OZ / 40 ML",
            specCover: sunscreen4,
            url: "sunscreen",
            details: [sunscreen4,sunscreen3,sunscreen2],
            homeUse: [coverD,homesun2,homesun3,homesun4],
            benefit: `
              <p>Sunscreen effective against UV and oxidation. Forms an effective protection on the skin.</p>
              <p>It has been clinically proved that it can effectively inhibit inflammation and adverse skin reactions caused by light damage and external pollutants, and help reduce pigmentation, so as to maintain healthy and natural skin.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Cyclopentasiloxane, Butylene Glycol, Ethylhexyl Methoxycinnamate, Cyclohexasiloxane, C12-15 Alkyl Benzoate, Ethylhexyl Salicylate, Lauryl PEG-9 Polydimethylsiloxyethyl Dimethicone, Diethylamino Hydroxybenzoyl Hexyl Benzoate, Styrene/DVB Crosspolymer, Sodium Chloride, Sorbitan Isostearate, Polymethylsilsesquioxane, Aluminum Hydroxide, Disteardimonium Hectorite, Phenoxyethanol, Isostearic Acid, Dimethicone/Vinyl Dimethicone Crosspolymer, Hydroxyacetophenone, Lecithin, Polyhydroxystearic Acid, Ethylhexyl Palmitate, Isopropyl Myristate, Trimethylsiloxysilicate, Ethylhexylglycerin, Silica, Polyglyceryl-3 Polyricinoleate, Avena Sativa (Oat) Kernel Oil, Bifida Ferment Lysate, Olea Europaea (Olive) Fruit Oil, Tocopherol, Ceramide NP, Sodium Benzoate, Acetic Acid, Lactic Acid, CI 77891, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>Apply evenly on clean and moisturized skin 15 minutes before exposure to sunlight.</p>
              
              <p>For daily use, please let the product get completely absorbed before making up.</p>
              <div class='end-line'></div>
            `,
          },
          h: {
            id: "NBSH921038",
            name: "SUNSCREEN",
            short: "sunscreen",
            specName: "Sunscreen",
            specVol: "1.35 OZ / 40 ML",
            specPrice: 165,
            specIntro: "1.35 OZ / 40 ML",
            specCover: sunscreen3,
            url: "sunscreen",
            details: [sunscreen4,sunscreen3,sunscreen2],
            benefit: `
              <p>Sunscreen effective against UV and oxidation. Forms an effective protection on the skin.</p>
              <p>It has been clinically proved that it can effectively inhibit inflammation and adverse skin reactions caused by light damage and external pollutants, and help reduce pigmentation, so as to maintain healthy and natural skin.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Cyclopentasiloxane, Butylene Glycol, Ethylhexyl Methoxycinnamate, CI 77891, Ethylhexyl Salicylate, C12-15 Alkyl Benzoate, Lauryl Peg-9 Polydimethylsiloxyethyl Dimethicone, Diethylamino Hydroxybenzoyl Hexyl Benzoate, Styrene/Dvb Crosspolymer, Cyclohexasiloxane, Sorbitan Isostearate, Sodium Chloride, Polymethylsilsesquioxane , Disteardimonium Hectorite, Aluminum Hydroxide, Phenoxyethanol, Isostearic Acid, Dimethicone/Vinyl Dimethicone Crosspolymer, Hydroxyacetophenone, Lecithine, Polyhydroxystearic Acid, Isopropyl Myristate, Ethylhexyl Palmitate, Trimethylsiloxysilicate, Ethylhexylglycerin, Silica, Polyglyceryl-3 Polyricinoleate, Avena Sativa Kernel Oil, Bifida Ferment Lysate, Olea Europaea (Olive) Fruit Oil, Tocopherol, Ceramide 3, 1,2-Hexanediol, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>Apply evenly on clean and moisturized skin 15 minutes before exposure to sunlight.</p>
              
              <p>For daily use, please let the product get completely absorbed before making up.</p>
              <div class='end-line'></div>
            `,
          },
          a: {
            id: "NBSH921029",
            name: "FACE MASK",
            short: "mask-bc",
            cato: "mask",
            specName: "Face Mask",
            specVol: "0.85 OZ / 25G × 6",
            specPrice: 395,
            specIntro: "1.06 OZ / 25G × 4 - 6",
            specCover: maskb,
            url: "face-mask-b",
            details: [maskb,mask3,mask2],
            ptype:'Bio-Cellulose',
            otype:'Lyocell Fiber',
            benefit: `
            <p>Biological fiber mask suitable for all skin types. It can improve your skin, support whitening, rejuvenation and help it resist aging.</p>
              <p>The product is made of plants and effective growth factors combining liposome technology. It has been clinically proven to have the following functions:</p>
              
              <p><span class="text-radio"></span>Restore moisture and strengthen the skin's protective barrier
              <br/><span class="text-radio"></span>Improve skin redness conditions and pigmentation of the skin<br/><span class="text-radio"></span>Restore skins damaged by surgery, makeup, or a bad life habits<br/><span class="text-radio"></span>Reduce the apparent wrinkles of the skin<br/><span class="text-radio"></span>Improve the firmness and elasticity of the skin</p>
              
              <p>The skin’s firmness and elasticity of 93% of the patients who have problems of pigmentation and skin damages have been improved after 2-4 weeks of usage of our facial mask.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Propanediol, Glycerin, Betaine, Hydroxyethyl Urea, Butylene Glycol, Niacinamide, Alpha-Arbutin, Saccharomyces/Rice Ferment Filtrate, Hydroxyacetophenone, Panthenol, Hydroxyethylcellulose, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Arginine, Sodium Hyaluronate, Xanthan Gum, Hydrolyzed Vegetable Protein, Caprylhydroxamic Acid, 1, 2-Hexanediol, Glyceryl Caprylate, Stephania Tetrandra Extract, Phenoxyethanol, Sodium Benzoate, Potassium Sorbate, Ethylhexylglycerin, Caprylyl Glycol, Disodium Phosphate, Polysorbate 60, Sodium Phosphate, Salix Alba (Willow) Bark Extract, Tocopheryl Acetate, Acetyl Glucosamine, 3-O-Ethyl Ascorbic Acid, Astaxanthin, 4-Butylresorcinol, Bisabolol, Olea Europaea (Olive) Fruit Oil, Glycyrrhiza Glabra Extract, Glabridin, Acetyl Hexapeptide-8, Hydroxypropyl Tetrahydropyrantriol, Propylene Glycol, Ceramide NP, Farnesol, Portulaca Oleracea Extract, PVP, Palmitoyl Tripeptide-5, Fullerenes, Arginine/Lysine Polypeptide, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>We suggest that you use this product after a complete cleansing of your face, in order to better absorb the ingredients and avoid allergies.</p>
              
              <p>For the vitality of important ingredients, please use it within six months from the production date.</p>
              <div class='end-line'></div>
            `,
          },
          f: {
            id: "NBSH921035",
            name: "FACE SCRUB",
            short: "scrub",
            specName: "Face Scrub",
            specVol: "1.7 OZ / 50 ML",
            specPrice: 125,
            specIntro: "1.7 OZ / 50 ML",
            specHome:"50ml - 129€",
            specCover: coverF,
            url: "face-scrub",
            details: [scrub4,scrub3,scrub2],
            homeUse: scrubR1,
            benefit: `
            <p>This facial Scrub is rich in precious extracts of Clay and minerals.</p>
              <p>Through its advanced deep cleaning technology, it can create an ideal balance environment for the skin, effectively discharge harmful substances blocking the pores, regain the regenerative texture of the skin in its natural state, and balance the water and oil of the skin.</p>
              <p>It has been clinically proved that it can improve the ion channel system of the cells and help the skin regenerate; It produces a refreshing feeling after cleaning, and it is a useful preparation for the follow-up skin care and treatments; It reduces gloss, uneven textures and surface peeling, while minimizing the visibility of skin pores.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Glycerin, Caprylic/Capric Triglyceride, Cetearyl Alcohol, Dimethicone, Sodium Polyacrylate, Juglans Regia (Walnut) Shell Powder, Sorbitan Stearate, Magnesium Aluminometasilicate, Glyceryl Stearate Citrate, Octanediol, Polysorbate 60, Ethylhexylglycerin, Xanthan Gum, Tocopherol, Lactic Acid, Hydroxypropyl Cyclodextrin, Sodium Lactate, Citrus Grandis (Grapefruit) Seed Extract, Disodium EDTA, Azelaic Acid, Artemisia Princeps Leaf Extract, Propylene Glycol, Phenoxyethanol, CI 77266, Orange Yu, Rosmarinus Officinalis (Rosemary) Leaf Oil</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>After a first cleaning of the skin, use the cleanser 2-3 times a month to improve complexion.</p>
              <p>Apply the clay with or without water. Squeeze about 4cm of clay on your fingertips and gently apply it on your face. Gently massage the clay on your cheeks and forehead in small circles. Please be careful when massaging around your eyes in order to avoid contact with eyes. Please maintain the product for 5 minutes to let the skin absorb, before washing thoroughly with water.</p>
              
              <p>* We recommend using this product under the professional guidance of our partner Spa salons or distributors.</p>
              <div class='end-line'></div>
            `,
          },
          g: {
            id: "NBSH921030",
            name: "FACE MASK",
            short: "mask",
            cato: "mask",
            specName: "Face Mask",
            specVol: "1.06 OZ / 30G × 4",
            specPrice: 165,
            specIntro: "4 Pack / 6 Pack",
            specCover: lf1,
            url: "face-mask",
            ptype:'Lyocell Fiber',
            otype:'Bio-Cellulose',
            details: [lf1,lf2,mask2],
            benefit: `
              <p>This Lyocell Fiber mask is rich in nourishing and repairing ingredients. It contains Alpha-Arbutin, Panthenol, Sodium Hyaluronate and other precious extracts. The liposomal technology in the form of micro-vesicles enters the skin and delays the first signs of aging, strengthens the skin's natural barrier and immunity, and helps it to better transition through occasional imbalances; ideal for use as a calming treatment before makeup or after laser.</p>
              <div class='end-line'></div>
            `,
            ingredients: `
              <p>Water/Aqua/Eau, Propanediol, Glycerin, Betaine, Hydroxyethyl Urea, Butylene Glycol, Alpha-Arbutin, Niacinamide, Hydroxyacetophenone, Panthenol, Hydroxyethylcellulose, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Arginine, Sodium Hyaluronate, Xanthan Gum, Caprylhydroxamic Acid, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, 1,2-Butanediol, Hydrogenated Lecithin, Vaccinium Angustifolium (Blueberry) Fruit Extract, Phytosteryl Oleate, Glyceryl Caprylate, Propylene Glycol, Stephania Tetrandra Extract, Phenoxyethanol, Sodium Benzoate, Potassium Sorbate, Portulaca Oleracea Extract, Ethylhexylglycerin, RNA, Phytosterols, Caprylyl Glycol, Acetyl Hexapeptide-8, Fragrance</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>We suggest that you use this product after a complete cleansing of your face, in order to better absorb the ingredients and avoid allergies.</p>
              
              <p>For the vitality of important ingredients, please use it within six months from the production date.</p>
              <div class='end-line'></div>
            `,
          },
          i: {
            id: "NBSH92101B",
            name: "GIFT BOX SERIES",
            short: "giftbox2",
            cato: "giftbox",
            specName: "Gift Box Series",
            specVol: "Serum × 1 & Face Cream x 1",
            specPrice: 795,
            specIntro: "50 ML + 30 ML",
            types: "Gift Box #2",
            specCover: giftboxall,
            url: "gift-box-series",
            details: [giftbox1,giftbox6,giftbox4],
            homeUse: [giftbox6,homebox2,homebox3,homebox4],
            benefit: '',
            officialNotes: '',
            ingredients: `
            <p>Serum<br/>1 OZ / 30 ML</p>
              <p>Water/Aqua/Eau, Butylene Glycol, Trehalose, Bis-PEG-18 Methyl Ether Dimethyl Silane, Niacinamide, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, Hydroxyacetophenone, Hydroxyethylpiperazine Ethane Sulfonic Acid, Glycerin, Arginine, Bifida Ferment Lysate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Hydroxypropyl Tetrahydropyrantriol, Troxerutin, Tremella Fuciformis Sporocarp Extract, Diphenyl Dimethicone, Triethylhexanoin, Sodium Benzoate, Phenoxyethanol, Potassium Sorbate, Polyglyceryl-10 Myristate, Disodium EDTA, Ethylhexylglycerin, Pentylene Glycol, Palmitoyl Tripeptide-5, Tapioca Starch, Tocopherol, Galactomyces Ferment Filtrate, Oligopeptide-1, Prunus Yedoensis Leaf Extract, PVP, Arginine/Lysine Polypeptide, Fullerenes, Fragrance</p>
              <br/>
              <p>Face Cream<br/>1.7 OZ / 50 ML</p>
              
              <p>Water/Aqua/Eau, Glycerin, Dimethicone, Isononyl Isononanoate, Hydrogenated Ethylhexyl Olivate, Cyclopentasiloxane, Butyrospermum Parkii (Shea) Butter, Panthenol, Polyglyceryl-3 Methylglucose Distearate, Behenyl Alcohol, Glyceryl Stearate Citrate, Hexyldecanol, Tocopheryl Acetate, Sodium Polyacrylate, Hydrogenated Olive Oil Unsaponifiables, Dimethicone/Vinyl Dimethicone Crosspolymer, Octanediol, Hydroxypropyl Tetrahydropyrantriol, Ethylhexyl Stearate, Olea Europaea (Olive) Fruit Oil, Ethylhexylglycerin, Avena Sativa (Oat) Kernel Oil, Bisabolol, Butylene Glycol, Arginine, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Trimethylsiloxysilicate, 1,2-Hexanediol, Sodium Hyaluronate, Ceramide NP, Trideceth-6, Citrus Grandis (Grapefruit) Seed Extract, Cetylhydroxyproline Palmitamide, Stearic Acid, Farnesol, Disodium EDTA, Hydrogenated Lecithin, Phytosteryl Oleate, Brassica Campestris (Rapeseed) Sterols, Artemisia Princeps Leaf Extract, Phytosterols, Fragrance</p>


              <div class='end-line'></div>
            `,
          },
          j: {
            id: "NBSH92102B",
            name: "GIFT BOX SERIES",
            short: "giftbox3",
            cato: "giftbox",
            specName: "Gift Box Series",
            specVol: "Serum × 1 & Face Cream x 1 & Face Mask x 1",
            specPrice: 945,
            specIntro: "",
            types: "Gift Box #3",
            specCover: giftboxall,
            url: "gift-box-3",
            details: [giftbox1,giftbox5,giftbox3],
            ingredients: `
            <p>Serum<br/>1 OZ / 30 ML</p>
            <p>Water/Aqua/Eau, Butylene Glycol, Trehalose, Bis-PEG-18 Methyl Ether Dimethyl Silane, Niacinamide, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, Hydroxyacetophenone, Hydroxyethylpiperazine Ethane Sulfonic Acid, Glycerin, Arginine, Bifida Ferment Lysate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Hydroxypropyl Tetrahydropyrantriol, Troxerutin, Tremella Fuciformis Sporocarp Extract, Diphenyl Dimethicone, Triethylhexanoin, Sodium Benzoate, Phenoxyethanol, Potassium Sorbate, Polyglyceryl-10 Myristate, Disodium EDTA, Ethylhexylglycerin, Pentylene Glycol, Palmitoyl Tripeptide-5, Tapioca Starch, Tocopherol, Galactomyces Ferment Filtrate, Oligopeptide-1, Prunus Yedoensis Leaf Extract, PVP, Arginine/Lysine Polypeptide, Fullerenes, Fragrance</p>
            <br/>
            <p>Face Cream<br/>1.7 OZ / 50 ML</p>
            
            <p>Water/Aqua/Eau, Glycerin, Dimethicone, Isononyl Isononanoate, Hydrogenated Ethylhexyl Olivate, Cyclopentasiloxane, Butyrospermum Parkii (Shea) Butter, Panthenol, Polyglyceryl-3 Methylglucose Distearate, Behenyl Alcohol, Glyceryl Stearate Citrate, Hexyldecanol, Tocopheryl Acetate, Sodium Polyacrylate, Hydrogenated Olive Oil Unsaponifiables, Dimethicone/Vinyl Dimethicone Crosspolymer, Octanediol, Hydroxypropyl Tetrahydropyrantriol, Ethylhexyl Stearate, Olea Europaea (Olive) Fruit Oil, Ethylhexylglycerin, Avena Sativa (Oat) Kernel Oil, Bisabolol, Butylene Glycol, Arginine, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Trimethylsiloxysilicate, 1,2-Hexanediol, Sodium Hyaluronate, Ceramide NP, Trideceth-6, Citrus Grandis (Grapefruit) Seed Extract, Cetylhydroxyproline Palmitamide, Stearic Acid, Farnesol, Disodium EDTA, Hydrogenated Lecithin, Phytosteryl Oleate, Brassica Campestris (Rapeseed) Sterols, Artemisia Princeps Leaf Extract, Phytosterols, Fragrance</p>
            <br/>
            <p>Face Mask<br/>Lyocell Fiber<br/>1.06 OZ / 30G  × 4</p>

            <p>Water/Aqua/Eau, Propanediol, Glycerin, Betaine, Hydroxyethyl Urea, Butylene Glycol, Alpha-Arbutin, Niacinamide, Hydroxyacetophenone, Panthenol, Hydroxyethylcellulose, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Arginine, Sodium Hyaluronate, Xanthan Gum, Caprylhydroxamic Acid, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, 1,2-Butanediol, Hydrogenated Lecithin, Vaccinium Angustifolium (Blueberry) Fruit Extract, Phytosteryl Oleate, Glyceryl Caprylate, Propylene Glycol, Stephania Tetrandra Extract, Phenoxyethanol, Sodium Benzoate, Potassium Sorbate, Portulaca Oleracea Extract, Ethylhexylglycerin, RNA, Phytosterols, Caprylyl Glycol, Acetyl Hexapeptide-8, Fragrance</p>
            <div class='end-line'></div>
            `,
            benefit: '',
            officialNotes: '',
          },
          k: {
            id: "NBSH921031",
            name: "HAND CREAM",
            short: "handcream",
            cato: "handcream",
            specName: "Hand Cream",
            specVol: "0.85 OZ / 25 ML",
            specPrice: 85,
            specIntro: "0.85 OZ / 25 ML",
            types: "Hand Cream",
            specCover: handcream1,
            url: "hand-cream",
            details: [handcream1,handcream2,handcream3],
            ingredients: `
            <p>Water/Aqua/Eau, Squalane, Glycerin, Dimethicone, C10-18 Triglycerides, Trehalose, Palmitic Acid, Myristic Acid, Sodium Polyacrylate, Polyglyceryl-6 Distearate, Phenoxyethanol, Stearic Acid, Chlorphenesin, Panthenol, Jojoba Esters, Hydroxypropyl Tetrahydropyrantriol, Polyglyceryl-3 Beeswax, Cetyl Alcohol, Ethylhexylglycerin, Sodium Hydroxide, Ectoin, Euglena Gracilis Polysaccharide, Soluble Collagen, Lactobacillus/Soymilk Ferment Filtrate, Butylene Glycol, Hydrolyzed Collagen, Pentylene Glycol, Fragrance</p>
            <div class='end-line'></div>
            `,
            benefit: `
              <p>A precious hand cream, enriched with specially tailored squalane, Pro-Xylane, and hydrolyzed collagen.</p>
              <p>It effectively improves conditions such as dryness and dullness of the hands, maintains better hydration and luminosity of the skin, and promotes the restoration of the skin's original elasticity and texture.</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>After cleaning your hands, squeeze an appropriate amount of cream onto the palm or back of your hand, then evenly apply it across the entire hand.</p>
              
              <p>For those particularly dry or severely dehydrated, it is recommended to use a larger amount and leave it on the hands for about 15 minutes to achieve a better repair effect.</p>
              <p>* We recommend using this product under the professional guidance of our partner Spa salons or distributors.</p>
              <div class='end-line'></div>
            `,
          },
          l: {
            id: "NBSH921032",
            name: "BODY LOTION",
            short: "bodylotion",
            cato: "bodylotion",
            specName: "Body Lotion",
            specVol: "6.8 OZ / 200 ML",
            specPrice: 95,
            specIntro: "6.8 OZ / 200 ML",
            types: "bodylotion",
            specCover: bodylotion1,
            url: "body-lotion",
            details: [bodylotion1,bodylotion2,bodylotion3],
            ingredients: `
            <p>Water/Aqua/Eau, Lactobacillus Ferment, Glycerin, Butyrospermum Parkii (Shea) Butter, Dimethicone, Butylene Glycol, Trehalose, Glyceryl Stearate, Betaine, Polyglyceryl-3 Methylglucose Distearate, Phenoxyethanol, PEG-100 Stearate, Niacinamide, Tocopheryl Acetate, Cetearyl Alcohol, Chlorphenesin, Hydrogenated Lecithin, Panthenol, Allantoin, Sodium Polyacrylate, Crambe Abyssinica Seed Oil, Argania Spinosa Kernel Oil, Hydroxypropyl Tetrahydropyrantriol, Bisabolol, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Lactobacillus, Ethylhexyl Stearate, Sodium Hyaluronate, Ethylhexylglycerin, Dipotassium Glycyrrhizate, 1,2-Hexanediol, Hydroxyacetophenone, Sodium Hydroxide, Farnesol, Trideceth-6, Stephania Tetrandra Extract, Elaeis Guineensis (Palm) Oil, Gossypium Herbaceum (Cotton) Seed Oil, Bidens Pilosa Extract, Linum Usitatissimum (Linseed) Seed Oil, Glabridin, Palmitoyl Tripeptide-5, Tocopherol, Disodium EDTA, Fragrance</p>
            <div class='end-line'></div>
            `,
            benefit: `
              <p>Enhances the skin's natural defense mechanisms, effectively nourishing and moisturizing.</p>
              <p>Formulated with a variety of precious ingredients and extracts, it effectively improves skin density and elasticity, preventing premature aging.</p>
              <p>Repairs minor skin damage, maintaining a healthy glow.</p>
              <div class='end-line'></div>
            `,
            officialNotes: `
              <p>After cleansing the entire body, apply an appropriate amount of this product to areas in need of care and gently massage in a circular motion until fully absorbed.</p>
              
              <p>* We recommend using this product under the professional guidance of our partner Spa salons or distributors.</p>
              <div class='end-line'></div>
            `,
          },
    },
    FR: {
      b: {
          id: "NBSH921036",
          name: "MOUSSE NETTOYANTE",
          short: "cleanser",
          specName: "Mousse Nettoyante",
          specVol: "3.4 OZ / 100 ML",
          specPrice: 105,
          specIntro: "3.4 OZ / 100 ML",
          specCover: coverA,
          url: "foam-cleanser",
          details: [coverA,cleanser3,cleanser2],
          benefit: `
          <p>Mousse nettoyante pour la peau convenant à tous les types de peaux, et permettant d'éliminer soigneusement la saleté et les cosmétiques sans priver la peau de ses huiles et graisses naturelles.</p>
            <p>Après un nettoyage en profondeur, la peau est éclatante de céramide, d'extraits de feuilles de mûres et d'autres agents efficaces. Il a été cliniquement prouvé que ce nettoyant peut:
            </p>
            
            <p><span class="text-radio"></span>Éliminer en douceur, et efficacement les impuretés et les cellules mortes de la peau<br/><span class="text-radio"></span>Fournir une forte protection antioxydante contre les rougeurs et les irritations<br/><span class="text-radio"></span>Aider à maintenir l'équilibre naturel de l'hydratation de la peau, la rendant lisse, douce et rafraîchissante</p>
            <div class='end-line'></div>
          `,
          ingredients: `
            
            <p>Water/Aqua/Eau, Potassium Cocoyl Glycinate, Glycerin, Cocamidopropyl Betaine, Disodium Cocoamphodiacetate, Sodium Chloride, Phenoxyethanol, Chamaecyparis Obtusa Water, Paeonia Suffruticosa Flower Water, Caprylyl Glycol, Niacinamide, Panthenol, Ethylhexylglycerin, Xanthan Gum, Lactic Acid, Disodium EDTA, Butylene Glycol, Hydroxyacetophenone, Sodium Hyaluronate, Fragrance</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Appliquer une à trois doses sur la peau sèche, masser doucement pour former une mousse crémeuse, puis rincer à l'eau tiède.</p>
            
            <p>Pour un nettoyage en profondeur, laisser la mousse agir sur la peau cinq minutes comme un mini masques.</p>
            <div class='end-line'></div>
          `,
        },
        e: {
          id: "NBSH921033",
          name: "CRÈME VISAGE",
          short: "cream",
          specName: "Crème Visage",
          specVol: "1.7 OZ / 50 ML",
          specPrice: 345,
          specIntro: "1.7 OZ / 50 ML",
          specCover: cream5,
          coverPos:"50% 100%",
          url: "face-cream",
          details: [cream5,cream3,cream2],
          homeUse: [cream5,homecream2,homecream3,homecream4],
          benefit: `
            <p>Une précieuse crème pour le visage qui maintient votre peau hydratée et répare la peau endommagée.<br/>
            Son complexe bioactif aide à réparer les dommages cutanés à court terme, notamment ceux liés à l’environnement. De multiples facteurs hydratants et réparateurs aident aussi à prévenir la sécheresse cutanée.</p>
            <p>Grâce à la technologie des liposomes, les ingrédients hydratants et réparateurs peuvent pénétrer dans la peau. Il a été prouvé cliniquement que cette crème pouvait améliorer efficacement les problèmes liés au mauvais entretien de la peau ainsi que les rougeurs, gonflements, assombrissements et autres problèmes postopératoires. Après 2 à 4 semaines d'utilisation, votre visage présentera une peau belle, élastique et lisse, et posera les bases d'une apparence plus jeune.</p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Glycerin, Dimethicone, Isononyl Isononanoate, Hydrogenated Ethylhexyl Olivate, Cyclopentasiloxane, Butyrospermum Parkii (Shea) Butter, Panthenol, Polyglyceryl-3 Methylglucose Distearate, Behenyl Alcohol, Glyceryl Stearate Citrate, Hexyldecanol, Tocopheryl Acetate, Sodium Polyacrylate, Hydrogenated Olive Oil Unsaponifiables, Dimethicone/Vinyl Dimethicone Crosspolymer, Octanediol, Hydroxypropyl Tetrahydropyrantriol, Ethylhexyl Stearate, Olea Europaea (Olive) Fruit Oil, Ethylhexylglycerin, Avena Sativa (Oat) Kernel Oil, Bisabolol, Butylene Glycol, Arginine, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Trimethylsiloxysilicate, 1,2-Hexanediol, Sodium Hyaluronate, Ceramide NP, Trideceth-6, Citrus Grandis (Grapefruit) Seed Extract, Cetylhydroxyproline Palmitamide, Stearic Acid, Farnesol, Disodium EDTA, Hydrogenated Lecithin, Phytosteryl Oleate, Brassica Campestris (Rapeseed) Sterols, Artemisia Princeps Leaf Extract, Phytosterols, Fragrance</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Après avoir soigneusement nettoyé la peau, placez la crème sur le bout des doigts, puis appliquez la crème sur votre visage et frottez doucement.</p>
            <p>Massez doucement autour de vos joues et de vos yeux en petits cercles. Veuillez être attentifs lors du massage autour de vos yeux pour éviter tout contact du produit avec vos yeux. Laissez agir le produit au moins 10 minutes avant d’appliquer un autre produit adapté à votre situation personnelle.</p>
            
            <p>*Nous vous suggérons d'utiliser ce produit avec les conseils professionnels de nos salons et revendeurs partenaires.</p>
            <div class='end-line'></div>
          `,
        },
        c: {
          id: "NBSH921034",
          name: "SÉRUM",
          short: "serum",
          specName: "Sèrum",
          specVol: "1 OZ / 30 ML",
          specPrice: 550,
          specIntro: "1 OZ / 30 ML",
          specCover: coverC,
          url: "serum",
          details: [coverC,serum3,serum2],
          homeUse: serumR1,
          benefit: `
            <p>Plusieurs types d'extraits naturels précieux ont été utilisés pour créer soigneusement cette essence luxueuse.<br/>
            De véritables agents de nutrition et de réparation de la peau aident la peau à se raffermir et à retrouver sa santé.</p>
            <p>Grâce à la technologie des liposomes, le sérum peut retarder efficacement le vieillissement naturel de la peau. il a été cliniquement prouvé qu'il pouvait rendre la peau plus jeune et plus compacte. Sa formule de combinaison à base d'une variété d'extraits naturels peut améliorer considérablement la relaxation, les ridules et la pigmentation du visage (y compris autour des yeux) et du cou, et contribuer à créer une apparence délicate.</p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Butylene Glycol, Trehalose, Bis-PEG-18 Methyl Ether Dimethyl Silane, Niacinamide, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, Hydroxyacetophenone, Hydroxyethylpiperazine Ethane Sulfonic Acid, Glycerin, Arginine, Bifida Ferment Lysate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Hydroxypropyl Tetrahydropyrantriol, Troxerutin, Tremella Fuciformis Sporocarp Extract, Diphenyl Dimethicone, Triethylhexanoin, Sodium Benzoate, Phenoxyethanol, Potassium Sorbate, Polyglyceryl-10 Myristate, Disodium EDTA, Ethylhexylglycerin, Pentylene Glycol, Palmitoyl Tripeptide-5, Tapioca Starch, Tocopherol, Galactomyces Ferment Filtrate, Oligopeptide-1, Prunus Yedoensis Leaf Extract, PVP, Arginine/Lysine Polypeptide, Fullerenes, Fragrance</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Après avoir nettoyé en profondeur votre peau, disposez du sérum sur le bout de vos doigts puis appliquez le sérum sur votre visage en frottant délicatement.</p>
            <p>Massez doucement vos joues, vos yeux et votre cou en petits cercles. Veuillez être attentifs lors du massage autour de vos yeux pour éviter tout contact du produit avec vos yeux. Laissez agir le produit au moins 10 minutes avant d’appliquer un autre produit adapté à votre situation personnelle.</p>
            
            <p>*Nous vous suggérons d'utiliser ce produit avec les conseils professionnels de nos salons et revendeurs partenaires.</p>
            <div class='end-line'></div>
          `,
        },
        d: {
          id: "NBSH921037",
          name: "CRÈME SOLAIRE",
          short: "sunscreen",
          specName: "Crème Solaire",
          specVol: "1.35 OZ / 40 ML",
          specPrice: 165,
          specIntro: "1.35 OZ / 40 ML",
          specCover: sunscreen4,
          url: "sunscreen",
          details: [sunscreen4,sunscreen3,sunscreen2],
          homeUse: [coverD,homesun2,homesun3,homesun4],
          benefit: `
            <p>Écran solaire efficace contre les UV et l'oxydation. Protège efficacement la peau à travers la formation d'une couche protectrice.</p>
            <p>Il a été cliniquement prouvé que l'écran solaire NIHPLOD pouvait efficacement inhiber l'inflammation et les réactions cutanées indésirables causées par la lumière et les polluants externes, et aider à réduire la pigmentation, afin de maintenir une peau saine et naturelle.
            </p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Cyclopentasiloxane, Butylene Glycol, Ethylhexyl Methoxycinnamate, Cyclohexasiloxane, C12-15 Alkyl Benzoate, Ethylhexyl Salicylate, Lauryl PEG-9 Polydimethylsiloxyethyl Dimethicone, Diethylamino Hydroxybenzoyl Hexyl Benzoate, Styrene/DVB Crosspolymer, Sodium Chloride, Sorbitan Isostearate, Polymethylsilsesquioxane, Aluminum Hydroxide, Disteardimonium Hectorite, Phenoxyethanol, Isostearic Acid, Dimethicone/Vinyl Dimethicone Crosspolymer, Hydroxyacetophenone, Lecithin, Polyhydroxystearic Acid, Ethylhexyl Palmitate, Isopropyl Myristate, Trimethylsiloxysilicate, Ethylhexylglycerin, Silica, Polyglyceryl-3 Polyricinoleate, Avena Sativa (Oat) Kernel Oil, Bifida Ferment Lysate, Olea Europaea (Olive) Fruit Oil, Tocopherol, Ceramide NP, Sodium Benzoate, Acetic Acid, Lactic Acid, CI 77891, Fragrance</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Appliquer uniformément sur une peau propre et hydratée, 15 minutes avant l'exposition au soleil.</p>
            
            <p>Pour une utilisation quotidienne, veuillez laisser le produit être absorbé complètement par votre peau avant de vous maquiller.</p>
            <div class='end-line'></div>
          `,
        },
        h: {
          id: "NBSH921038",
          name: "CRÈME SOLAIRE",
          short: "sunscreen",
          specName: "Crème Solaire",
          specVol: "1.35 OZ / 40 ML",
          specPrice: 165,
          specIntro: "1.35 OZ / 40 ML",
          specCover: sunscreen3,
          url: "sunscreen",
          details: [sunscreen4,sunscreen3,sunscreen2],
          benefit: `
            <p>Écran solaire efficace contre les UV et l'oxydation. Protège efficacement la peau à travers la formation d'une couche protectrice.</p>
            <p>Il a été cliniquement prouvé que l'écran solaire NIHPLOD pouvait efficacement inhiber l'inflammation et les réactions cutanées indésirables causées par la lumière et les polluants externes, et aider à réduire la pigmentation, afin de maintenir une peau saine et naturelle.
            </p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Cyclopentasiloxane, Butylène Glycol, Ethylhexyl Methoxycinnamate, CI 77891, Ethylhexyl Salicylate, C12-15 Alkyl Benzoate, Lauryl Peg-9 Polydimethylsiloxyethyl Dimethicone, Diethylamino Hydroxybenzoyl Hexyl Benzoate, Styrène/Dvb Crosspolymer, Cyclohexasiloxane, Sorbitan Isostearate, Sodium Disteimonite, Polymethylsilsils Hydroxyde d'Aluminium, Phénoxyéthanol, Acide Isostéarique, Polymère Croisé De Diméthicone/Vinyldiméthicone, Hydroxyacétophénone, Lécithine, Acide Polyhydroxystéarique, Myristate d'Isopropyle, Palmitate d'Éthylhexyle, Triméthylsiloxysilicate, Éthylhexylglycérine, Silice, Polyricinoléate De Polyglycéryle-3, Huile De Noyau d'Avena Sativa, Lysat De Ferment De Bifida, Olea Europaea (Olive), Huile De Fruit, Tocophérol, Céramide 3, 1,2-Hexanediol, Parfum</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Appliquer uniformément sur une peau propre et hydratée, 15 minutes avant l'exposition au soleil.</p>
            
            <p>Pour une utilisation quotidienne, veuillez laisser le produit être absorbé complètement par votre peau avant de vous maquiller.</p>
            <div class='end-line'></div>
          `,
        },
        a: {
          id: "NBSH921029",
          name: "MASQUE VISAGE",
          short: "mask",
          specName: "Masque Visage",
          specVol: "0.85 OZ / 25G × 6",
          specPrice: 395,
          specIntro: "1.06 OZ / 30G × 4 - 6",
          specCover: maskb,
          url: "face-mask-b",
          details: [maskb,mask3,mask2],
          ptype:'Bio-Cellulose',
          otype:'Lyocell Fiber',
          benefit: `
          <p>Masque en fibre biologique convenant à tous les types de peaux. Peut améliorer la peau, éclaircir le teint et lutter contre les signes du vieillissement.</p>
            <p>Le produit est fabriqué par la plante et facteurs de croissance effectifs en combinant la technologie de liposome. Il a été démontré cliniquement qu’il a des fonctions de:</p>
            
            <p><span class="text-radio"></span>Restaurer l’hydratation et renforcer la barrière protectrice de la peau
            <br/><span class="text-radio"></span>Améliorer les rougeurs de la peau et la pigmentation de la peau<br/><span class="text-radio"></span>Restaurer la peau endommagée par la chirurgie, le maquillage ou une mauvaise habitude de vie<br/><span class="text-radio"></span>Réduire l’apparence des ridules et des rides<br/><span class="text-radio"></span>Améliorer la fermeté et l’élasticité de la peau</p>
            
            <p>La fermeté et l’élasticité de la peau de 93% des patients qui ont des problèmes de pigmentation et endommagement de la peau se sont améliorées après 2 à 4 semaine d’utilisation du masque facial.</p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Propanediol, Glycerin, Betaine, Hydroxyethyl Urea, Butylene Glycol, Niacinamide, Alpha-Arbutin, Saccharomyces/Rice Ferment Filtrate, Hydroxyacetophenone, Panthenol, Hydroxyethylcellulose, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Arginine, Sodium Hyaluronate, Xanthan Gum, Hydrolyzed Vegetable Protein, Caprylhydroxamic Acid, 1, 2-Hexanediol, Glyceryl Caprylate, Stephania Tetrandra Extract, Phenoxyethanol, Sodium Benzoate, Potassium Sorbate, Ethylhexylglycerin, Caprylyl Glycol, Disodium Phosphate, Polysorbate 60, Sodium Phosphate, Salix Alba (Willow) Bark Extract, Tocopheryl Acetate, Acetyl Glucosamine, 3-O-Ethyl Ascorbic Acid, Astaxanthin, 4-Butylresorcinol, Bisabolol, Olea Europaea (Olive) Fruit Oil, Glycyrrhiza Glabra Extract, Glabridin, Acetyl Hexapeptide-8, Hydroxypropyl Tetrahydropyrantriol, Propylene Glycol, Ceramide NP, Farnesol, Portulaca Oleracea Extract, PVP, Palmitoyl Tripeptide-5, Fullerenes, Arginine/Lysine Polypeptide, Fragrance</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Nous vous conseillons d’utiliser ce produit après avoir nettoyé complètement votre visage, afin de mieux absorber les ingrédients et éviter les allergies inutiles.</p>
            
            <p>Pour la vitalité des ingrédients importants, veuillez l'utiliser dans les six mois suivant la date de production.</p>
            <div class='end-line'></div>
          `,
        },
        f: {
          id: "NBSH921035",
          name: "CRÈME EXFOLIANTE",
          short: "scrub",
          specName: "Crème Exfloliante",
          specVol: "1.7 OZ / 50 ML",
          specPrice: 125,
          specIntro: "1.7 OZ / 50 ML",
          specCover: coverF,
          url: "face-scrub",
          details: [scrub4,scrub3,scrub2],
          homeUse: scrubR1,
          benefit: `
            <p>Riche en extraits précieux d'argile et minéraux de gommage du visage.</p>
            <p>Grâce à une technologie de nettoyage en profondeur avancée, il peut créer un environnement d'équilibre idéal pour la peau, évacuer efficacement la cutine et les substances nocives bloquant les pores, retrouver la texture régénératrice naturelle et équilibrer l'eau et l'huile de la peau.</p>
            <p>Il a été prouvé qu'il peut améliorer le système de canaux ioniques des cellules et aider la peau à se régénérer.</p>
            <p>Il produit une sensation rafraîchissante après le nettoyage et fournit une préparation utile pour les soins et le traitement de suivi de la peau.
            Il réduit la brillance, la texture inégale et le pelage de la surface, tout en minimisant la visibilité des pores.</p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Glycerin, Caprylic/Capric Triglyceride, Cetearyl Alcohol, Dimethicone, Sodium Polyacrylate, Juglans Regia (Walnut) Shell Powder, Sorbitan Stearate, Magnesium Aluminometasilicate, Glyceryl Stearate Citrate, Octanediol, Polysorbate 60, Ethylhexylglycerin, Xanthan Gum, Tocopherol, Lactic Acid, Hydroxypropyl Cyclodextrin, Sodium Lactate, Citrus Grandis (Grapefruit) Seed Extract, Disodium EDTA, Azelaic Acid, Artemisia Princeps Leaf Extract, Propylene Glycol, Phenoxyethanol, CI 77266, Orange Yu, Rosmarinus Officinalis (Rosemary) Leaf Oil</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Après avoir nettoyé la peau, utilisez le 2-3 fois par mois pour améliorer le teint.</p>
            <p>Appliquer l'argile avec les mains ou sans eau. Disposez 4 cm d'argile sur le bout des doigts et appliquez-la doucement sur le visage. Massez doucement l'argile sur la joue et le front en petits cercles. Veuillez prêter une attention particulière au massage autour des yeux pour éviter tout contact. Veuillez laisser agir pendant 5 minutes avant de vous laver soigneusement la peau.</p>
            
            <p>*Nous vous suggérons d'utiliser ce produit avec les conseils professionnels de nos salons et revendeurs partenaires.</p>
            <div class='end-line'></div>
          `,
        },
        g: {
          id: "NBSH921030",
          name: "MASQUE VISAGE",
          short: "mask",
          specName: "Masque Visage",
          specVol: "1.06 OZ / 30G × 4",
          specPrice: 165,
          specIntro: "4 Pack / 6 Pack",
          specCover: lf1,
          url: "face-mask",
          ptype:'Lyocell Fiber',
          otype:'Bio-Cellulose',
          details: [lf1,lf2,mask2],
          benefit: `
          
            <p>Ce masque Lyocell Fiber est riche en ingrédients nourrissants et réparateurs: Alpha-Arbutin, Panthénol, Hyaluronate de sodium et autres extraits précieux. La technologie liposomale sous forme de micro-vésicules pénètre dans la peau et retarde les premiers signes de vieillissement, renforce la barrière naturelle et le système immunitaire de la peau et l’aide à faire la transition en cas de déséquilibres occasionnels; idéal pour une utilisation comme traitement calmant avant le maquillage ou après un laser.</p>
            <div class='end-line'></div>
          `,
          ingredients: `
            <p>Water/Aqua/Eau, Propanediol, Glycerin, Betaine, Hydroxyethyl Urea, Butylene Glycol, Alpha-Arbutin, Niacinamide, Hydroxyacetophenone, Panthenol, Hydroxyethylcellulose, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Arginine, Sodium Hyaluronate, Xanthan Gum, Caprylhydroxamic Acid, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, 1,2-Butanediol, Hydrogenated Lecithin, Vaccinium Angustifolium (Blueberry) Fruit Extract, Phytosteryl Oleate, Glyceryl Caprylate, Propylene Glycol, Stephania Tetrandra Extract, Phenoxyethanol, Sodium Benzoate, Potassium Sorbate, Portulaca Oleracea Extract, Ethylhexylglycerin, RNA, Phytosterols, Caprylyl Glycol, Acetyl Hexapeptide-8, Fragrance</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Nous vous conseillons d’utiliser ce produit après avoir nettoyé complètement votre visage, afin de mieux absorber les ingrédients et éviter les allergies inutiles.</p>
            
            <p>Pour la vitalité des ingrédients importants, veuillez l'utiliser dans les six mois suivant la date de production.</p>
            <div class='end-line'></div>
          `,
        },
        i: {
          id: "NBSH92101B",
          name: "SÉRIE COFFRET CADEAU",
          short: "giftbox2",
          cato: "giftbox",
          specName: "Coffret Cadeau Série",
          specVol: "Sérum × 1 & Crème Visage x 1",
          specPrice: 795,
          specIntro: "50 ML + 30 ML",
          types: "Coffret Cadeau #2",
          specCover: giftboxall,
          url: "gift-box-series",
          details: [giftbox1,giftbox6,giftbox4],
          homeUse: [giftbox6,homebox2,homebox3,homebox4],
          benefit: '',
          officialNotes: '',
          ingredients: `
          <p>Sérum<br/>1 OZ / 30 ML</p>
            <p>Water/Aqua/Eau, Butylene Glycol, Trehalose, Bis-PEG-18 Methyl Ether Dimethyl Silane, Niacinamide, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, Hydroxyacetophenone, Hydroxyethylpiperazine Ethane Sulfonic Acid, Glycerin, Arginine, Bifida Ferment Lysate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Hydroxypropyl Tetrahydropyrantriol, Troxerutin, Tremella Fuciformis Sporocarp Extract, Diphenyl Dimethicone, Triethylhexanoin, Sodium Benzoate, Phenoxyethanol, Potassium Sorbate, Polyglyceryl-10 Myristate, Disodium EDTA, Ethylhexylglycerin, Pentylene Glycol, Palmitoyl Tripeptide-5, Tapioca Starch, Tocopherol, Galactomyces Ferment Filtrate, Oligopeptide-1, Prunus Yedoensis Leaf Extract, PVP, Arginine/Lysine Polypeptide, Fullerenes, Fragrance</p>
            <br/>
            <p>Crème Visage<br/>1.7 OZ / 50 ML</p>
            
            <p>Water/Aqua/Eau, Glycerin, Dimethicone, Isononyl Isononanoate, Hydrogenated Ethylhexyl Olivate, Cyclopentasiloxane, Butyrospermum Parkii (Shea) Butter, Panthenol, Polyglyceryl-3 Methylglucose Distearate, Behenyl Alcohol, Glyceryl Stearate Citrate, Hexyldecanol, Tocopheryl Acetate, Sodium Polyacrylate, Hydrogenated Olive Oil Unsaponifiables, Dimethicone/Vinyl Dimethicone Crosspolymer, Octanediol, Hydroxypropyl Tetrahydropyrantriol, Ethylhexyl Stearate, Olea Europaea (Olive) Fruit Oil, Ethylhexylglycerin, Avena Sativa (Oat) Kernel Oil, Bisabolol, Butylene Glycol, Arginine, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Trimethylsiloxysilicate, 1,2-Hexanediol, Sodium Hyaluronate, Ceramide NP, Trideceth-6, Citrus Grandis (Grapefruit) Seed Extract, Cetylhydroxyproline Palmitamide, Stearic Acid, Farnesol, Disodium EDTA, Hydrogenated Lecithin, Phytosteryl Oleate, Brassica Campestris (Rapeseed) Sterols, Artemisia Princeps Leaf Extract, Phytosterols, Fragrance</p>


            <div class='end-line'></div>
          `,
        },
        j: {
          id: "NBSH92102B",
          name: "SÉRIE COFFRET CADEAU",
          short: "giftbox3",
          cato: "giftbox",
          specName: "Coffret Cadeau Série",
          specVol: "Sérum × 1 & Crème Visage x 1 & Masque Facial x 1",
          specPrice: 945,
          specIntro: "",
          types: "Coffret Cadeau #3",
          specCover: giftboxall,
          url: "gift-box-3",
          details: [giftbox1,giftbox5,giftbox3],
          benefit: '',
          officialNotes: '',
          ingredients: `
          <p>Sérum<br/>1 OZ / 30 ML</p>
          <p>Water/Aqua/Eau, Butylene Glycol, Trehalose, Bis-PEG-18 Methyl Ether Dimethyl Silane, Niacinamide, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, Hydroxyacetophenone, Hydroxyethylpiperazine Ethane Sulfonic Acid, Glycerin, Arginine, Bifida Ferment Lysate, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Hydroxypropyl Tetrahydropyrantriol, Troxerutin, Tremella Fuciformis Sporocarp Extract, Diphenyl Dimethicone, Triethylhexanoin, Sodium Benzoate, Phenoxyethanol, Potassium Sorbate, Polyglyceryl-10 Myristate, Disodium EDTA, Ethylhexylglycerin, Pentylene Glycol, Palmitoyl Tripeptide-5, Tapioca Starch, Tocopherol, Galactomyces Ferment Filtrate, Oligopeptide-1, Prunus Yedoensis Leaf Extract, PVP, Arginine/Lysine Polypeptide, Fullerenes, Fragrance</p>
          <br/>
          <p>Crème Visage<br/>1.7 OZ / 50 ML</p>
          
          <p>Water/Aqua/Eau, Glycerin, Dimethicone, Isononyl Isononanoate, Hydrogenated Ethylhexyl Olivate, Cyclopentasiloxane, Butyrospermum Parkii (Shea) Butter, Panthenol, Polyglyceryl-3 Methylglucose Distearate, Behenyl Alcohol, Glyceryl Stearate Citrate, Hexyldecanol, Tocopheryl Acetate, Sodium Polyacrylate, Hydrogenated Olive Oil Unsaponifiables, Dimethicone/Vinyl Dimethicone Crosspolymer, Octanediol, Hydroxypropyl Tetrahydropyrantriol, Ethylhexyl Stearate, Olea Europaea (Olive) Fruit Oil, Ethylhexylglycerin, Avena Sativa (Oat) Kernel Oil, Bisabolol, Butylene Glycol, Arginine, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Trimethylsiloxysilicate, 1,2-Hexanediol, Sodium Hyaluronate, Ceramide NP, Trideceth-6, Citrus Grandis (Grapefruit) Seed Extract, Cetylhydroxyproline Palmitamide, Stearic Acid, Farnesol, Disodium EDTA, Hydrogenated Lecithin, Phytosteryl Oleate, Brassica Campestris (Rapeseed) Sterols, Artemisia Princeps Leaf Extract, Phytosterols, Fragrance</p>
          <br/>
          <p>Masque Facial<br/>Lyocell Fiber<br/>1.06 OZ / 30G  × 4</p>

          <p>Water/Aqua/Eau, Propanediol, Glycerin, Betaine, Hydroxyethyl Urea, Butylene Glycol, Alpha-Arbutin, Niacinamide, Hydroxyacetophenone, Panthenol, Hydroxyethylcellulose, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Arginine, Sodium Hyaluronate, Xanthan Gum, Caprylhydroxamic Acid, Hydrolyzed Vegetable Protein, 1,2-Hexanediol, 1,2-Butanediol, Hydrogenated Lecithin, Vaccinium Angustifolium (Blueberry) Fruit Extract, Phytosteryl Oleate, Glyceryl Caprylate, Propylene Glycol, Stephania Tetrandra Extract, Phenoxyethanol, Sodium Benzoate, Potassium Sorbate, Portulaca Oleracea Extract, Ethylhexylglycerin, RNA, Phytosterols, Caprylyl Glycol, Acetyl Hexapeptide-8, Fragrance</p>
          <div class='end-line'></div>
          `,
        },
        k: {
          id: "NBSH921031",
          name: "CRÈME MAINS",
          short: "handcream",
          cato: "handcream",
          specName: "Crème Mains",
          specVol: "0.85 OZ / 25 ML",
          specPrice: 85,
          specIntro: "0.85 OZ / 25 ML",
          types: "Crème Mains",
          specCover: handcream1,
          url: "hand-cream",
          details: [handcream1,handcream2,handcream3],
          ingredients: `
          <p>Eau/Aqua/Eau, Squalane, Glycérine, Diméthicone, Triglycérides C10-18, Tréhalose, Acide Palmitique, Acide Myristique, Polyacrylate de Sodium, Polyglycéryl-6 Distearate, Phénoxyéthanol, Acide Stéarique, Chlorphénésine, Panthénol, Esters de Jojoba, Hydroxypropyl Tetrahydropyrantriol, Cire d'Abeille Polyglycéryl-3, Alcool Cétylique, Ethylhexylglycérine, Hydroxyde de Sodium, Ectoïne, Polysaccharide d'Euglena Gracilis, Collagène Soluble, Filtrat de Fermentation de Lactobacillus/Soja, Butylène Glycol, Collagène Hydrolysé, Pentylène Glycol, Fragrance
          </p>
          <br/>
          `,
          benefit: `
            <p>Une crème pour les mains précieuse, enrichie en squalane spécialement adapté, Pro-Xylane et collagène hydrolysé.</p>
            <p>Elle améliore efficacement les problèmes de sécheresse et de ternissement des mains, maintient une meilleure hydratation et luminosité de la peau, et favorise la restauration de l'élasticité et de la texture originelles de la peau.</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Après avoir nettoyé vos mains, pressez une quantité appropriée de crème sur la paume ou le dos de votre main, puis appliquez-la uniformément sur toute la main.</p>
            
            <p>Pour ceux particulièrement secs ou sévèrement déshydratés, il est recommandé d'utiliser une plus grande quantité et de la laisser sur les mains pendant environ 15 minutes pour obtenir un meilleur effet de réparation.</p>
            <p>Nous recommandons d'utiliser ce produit sous la guidance professionnelle de nos salons Spa partenaires ou distributeurs.</p>
            <div class='end-line'></div>
          `,
        },
        l: {
          id: "NBSH921032",
          name: "Lotion Corporelle",
          short: "bodylotion",
          cato: "bodylotion",
          specName: "Lotion Corporelle",
          specVol: "6.8 OZ / 200 ML",
          specPrice: 95,
          specIntro: "6.8 OZ / 200 ML",
          types: "bodylotion",
          specCover: bodylotion1,
          url: "body-lotion",
          details: [bodylotion1,bodylotion2,bodylotion3],
          ingredients: `
          <p>Eau/Aqua/Eau, Ferment de Lactobacillus, Glycérine, Beurre de Butyrospermum Parkii (Karité), Diméthicone, Butylène Glycol, Tréhalose, Stéarate de Glycéryle, Bétaïne, Distéarate de Méthylglucose Polyglycéryl-3, Phénoxyéthanol, Stéarate de PEG-100, Niacinamide, Acétate de Tocophéryle, Alcool Cétéarylique, Chlorphénésine, Lécithine Hydrogénée, Panthénol, Allantoïne, Polyacrylate de Sodium, Huile de Graine de Crambe Abyssinica, Huile de Noyau d'Argania Spinosa, Tetrahydropyrantriol Hydroxypropyl, Bisabolol, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Lactobacillus, Stéarate d'Éthylhexyle, Hyaluronate de Sodium, Éthylhexylglycérine, Glycyrrhizate Dipotassique, 1,2-Hexanediol, Hydroxyacétophénone, Hydroxyde de Sodium, Farnésol, Trideceth-6, Extrait de Stephania Tetrandra, Huile de Elaeis Guineensis (Palm), Huile de Graines de Gossypium Herbaceum (Coton), Extrait de Bidens Pilosa, Huile de Graines de Linum Usitatissimum (Lin), Glabridine, Palmitoyl Tripeptide-5, Tocophérol, EDTA Disodique, Fragrance</p>
          <div class='end-line'></div>
          `,
          benefit: `
            <p>Renforce efficacement les défenses naturelles de la peau, en assurant sa nutrition et son hydratation.</p>
            
            <p>Grâce à une variété d'ingrédients précieux et d'extraits, améliore efficacement la densité et l'élasticité de la peau, prévenant le vieillissement prématuré.</p>
            
            <p>Répare les légers dommages cutanés, maintenant un éclat sain.</p>
            <div class='end-line'></div>
          `,
          officialNotes: `
            <p>Après avoir nettoyé l'ensemble du corps, appliquez une quantité appropriée de ce produit sur les zones nécessitant des soins et massez doucement en mouvements circulaires jusqu'à absorption complète.
            </p>
            
            <p>Nous recommandons l'utilisation de ce produit sous la supervision professionnelle de nos salons de spa partenaires ou de nos distributeurs.</p>
            <div class='end-line'></div>
          `,
        },
  }
}