import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import {Component} from 'react';
import './App.less';
import PageHome from './pages/home';
import PageCollections from './pages/collection';
import PageDiscover from './pages/discover/index';
import PageExclusive from './pages/exclusive/index';

import PageTac from './pages/botnav/tac';
import PageFaq from './pages/botnav/faq';
import PageMembership from './pages/botnav/membership';
import PageContact from './pages/botnav/contact';
import PageLogin from './pages/botnav/login';

import PageFRHome from './pagesFR/home';
import PageFRCollections from './pagesFR/collection';
import PageFRDiscover from './pagesFR/discover/index';
import PageFRExclusive from './pagesFR/exclusive/index';

import PageFRTac from './pagesFR/botnav/tac';
import PageFRFaq from './pagesFR/botnav/faq';
import PageFRMembership from './pagesFR/botnav/membership';
import PageFRContact from './pagesFR/botnav/contact';
import PageFRLogin from './pagesFR/botnav/login';

import inst from './common/images/instgram.png';
import { CSSTransition } from 'react-transition-group';
import $ from 'jquery';
import { accept, checkCookie, changeUrl,checkPolicy} from './common/changeUrl';
import Cookies from 'universal-cookie';
import ScrollToTop from "./component/scroll";
import Header from './component/header';
import Cart from './component/cart';
import HeaderFR from './component/headerFR';
import CartFR from './component/cartFR';

import { products } from './component/products';

import PageCleanser from './pages/products/cleanser';
import PageCream from './pages/products/cream';
import PageMask from './pages/products/mask';
import PageMaskB from './pages/products/maskb';
import PageSerum from './pages/products/serum';
import PageScrub from './pages/products/scrub';
import PageSunscreen from './pages/products/sunscreen';
import PageGifxBox2 from './pages/products/giftbox2';
import PageGifxBox3 from './pages/products/giftbox3';
import PageHandcream from './pages/products/handcream';
import PageBodylotion from './pages/products/bodylotion';

import PageFRCleanser from './pagesFR/products/cleanser';
import PageFRCream from './pagesFR/products/cream';
import PageFRMask from './pagesFR/products/mask';
import PageFRSerum from './pagesFR/products/serum';
import PageFRScrub from './pagesFR/products/scrub';
import PageFRSunscreen from './pagesFR/products/sunscreen';
import PageFRGifxBox2 from './pagesFR/products/giftbox2';
import PageFRGifxBox3 from './pagesFR/products/giftbox3';
import PageFRHandcream from './pagesFR/products/handcream';
import PageFRBodylotion from './pagesFR/products/bodylotion';

import * as Request from './common/request';
import {upperLaundryCookie} from './common/utils'

$('html').animate({scrollTop: '0px'})
const cookies = new Cookies();



const routes = [
  { path: '/', name: 'Home', Component : PageHome},
  { path: '/collection', name: 'Collections', Component : PageCollections },
  { path: '/discover', name: 'Discover', Component : PageDiscover },
  { path: '/exclusive', name: 'Exclusive', Component : PageExclusive },
  { path: '/' + products.EN.g.url, name: products.EN.g.specName, Component : PageMask},
  { path: '/' + products.EN.a.url, name: products.EN.a.specName, Component : PageMaskB},
  { path: '/' + products.EN.b.url, name: products.EN.b.specName,Component : PageCleanser},
  { path: '/' + products.EN.c.url, name: products.EN.c.specName, Component : PageSerum},
  { path: '/' + products.EN.d.url, name: products.EN.d.specName, Component : PageSunscreen},
  { path: '/' + products.EN.e.url, name: products.EN.e.specName, Component : PageCream},
  { path: '/' + products.EN.f.url, name: products.EN.f.specName, Component : PageScrub},
  { path: '/' + products.EN.i.url, name: products.EN.i.specName, Component : PageGifxBox2},
  { path: '/' + products.EN.j.url, name: products.EN.j.specName, Component : PageGifxBox3},
  { path: '/' + products.EN.k.url, name: products.EN.k.specName, Component : PageHandcream},
  { path: '/' + products.EN.l.url, name: products.EN.l.specName, Component : PageBodylotion},
  { path: '/tac', name: 'TAC', Component : PageTac },
  { path: '/faq', name: 'FAQ', Component : PageFaq },
  { path: '/membership', name: 'Membership', Component : PageMembership },
  { path: '/contact', name: 'Contact', Component : PageContact },
  { path: '/login', name: 'Login', Component : PageLogin },
]

const routesFR = [
  { path: '/', name: 'Home', Component : PageFRHome},
  { path: '/collection', name: 'Collections', Component : PageFRCollections },
  { path: '/discover', name: 'Discover', Component : PageFRDiscover },
  { path: '/exclusive', name: 'Exclusive', Component : PageFRExclusive },
  { path: '/' + products.FR.g.url, name: products.FR.g.specName, Component : PageFRMask},
  { path: '/' + products.FR.b.url, name: products.FR.b.specName,Component : PageFRCleanser},
  { path: '/' + products.FR.c.url, name: products.FR.c.specName, Component : PageFRSerum},
  { path: '/' + products.FR.d.url, name: products.FR.d.specName, Component : PageFRSunscreen},
  { path: '/' + products.FR.e.url, name: products.FR.e.specName, Component : PageFRCream},
  { path: '/' + products.FR.f.url, name: products.FR.f.specName, Component : PageFRScrub},
  { path: '/' + products.FR.i.url, name: products.FR.i.specName, Component : PageFRGifxBox2},
  { path: '/' + products.FR.j.url, name: products.FR.j.specName, Component : PageFRGifxBox3},
  { path: '/' + products.FR.k.url, name: products.FR.k.specName, Component : PageFRHandcream},
  { path: '/' + products.FR.l.url, name: products.FR.l.specName, Component : PageFRBodylotion},
  { path: '/tac', name: 'TAC', Component : PageFRTac },
  { path: '/faq', name: 'FAQ', Component : PageFRFaq },
  { path: '/membership', name: 'Membership', Component : PageFRMembership },
  { path: '/contact', name: 'Contact', Component : PageFRContact },
  { path: '/login', name: 'Login', Component : PageFRLogin },

]

var lang = 'EN'

const pageText = {
  EN:{
    cookieTip: `We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. <br/>We also share information about your use of our site with our social media, advertising and analytics partners.`,
    cookiePolicy:"Cookie policy",
    footerNavItems:["tac","faq","membership","contact","language"],
    rights: "© 2025 NIHPLOD. ALL RIGHTS RESERVED.",
  },
  FR:{
    cookieTip: `Nous utilisons des cookies pour personnaliser le contenu et les publicités, pour fournir des fonctionnalités de médias sociaux et pour analyser notre trafic. <br/>Nous partageons également des informations sur votre utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse.`,
    cookiePolicy:"Politique relative aux cookies",
    footerNavItems:["tac","faq","adhésion", "contact","language"],
    rights: "© 2025 NIHPLOD. TOUS LES DROITS SONT RÉSERVÉS.",
  }
}

const viewPolicy = () => {
  var loca = window.location.href
  if(loca.indexOf("tac") < 0){
    $(".cookie-policy").css('bottom','-10vh')
    checkCookie()
  } else {
    checkPolicy("privacy-policy")
  }
}

const orignalSetItem = sessionStorage.setItem
  sessionStorage.setItem = function (key, newValue) {
    const setItemEvent = new Event("setItemEvent")
    setItemEvent.newValue = newValue
    window.dispatchEvent(setItemEvent)
    orignalSetItem.apply(this, arguments)
  }

class App extends Component  {
  componentWillMount(){
    if(JSON.parse(sessionStorage.getItem("cart")) != null){
      var currentlang = JSON.parse(sessionStorage.getItem("cart")).lang;
    } else {
      var currentlang = 'EN'
    }
    if(currentlang === "FR"){
      lang = 'FR'
    } else if(currentlang === "EN"){
      lang = 'EN'
    }

    checkCookie()
  }

  componentDidMount(){
    window.history.scrollRestoration = 'manual'
  }

  render (){
    return (
      <div className="App">
        <Router>
        {lang === 'EN' ?
        <div>
          <div className={`cookie-policy ${cookies.get('cookie') === "accepted" ? "accepted" : ""}`}>
            <div class="cookie-policy-description"><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.EN.cookieTip) }}></span><Link to={{pathname: "/tac" ,anchor: 'privacy-policy'}} className="cookie-policy-content" onClick={() => {viewPolicy()}}><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.EN.cookiePolicy) }}></span></Link>.</div><svg title="Accepter" className='cancel-icon' width="10" height="9" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {accept()}}><path d="M8.5 1.25 5 4.75l3.5 3.5M1 1.25l3.5 3.5L1 8.25"></path></svg>
          </div>
          <Cart></Cart>
          <div className='slideMask'></div>
          <div className="top-container">
            <div className='slideMask2'></div>
            <div className='top-mask'>
            <Header></Header>
              {routes.map(({ path, Component  }) => (
                <Route key={path} exact path={path}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      appear={true}
                      timeout={{
                        appear: 1500,
                        enter: 2000,
                        exit: 1200,
                      }}
                      classNames="page"
                      unmountOnExit
                    >
                      <div className="page">
                        <Component />
                        <div className='footer'>
                          <div className='footer-divider'></div>
                          <div className='footer-nav'>
                            <Link to="/faq" className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.EN.footerNavItems[1]}</Link>
                            <Link to={{pathname: "/tac" ,anchor: "term-and-condition"}} className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.EN.footerNavItems[0]}</Link>
                            <Link to="/login" className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.EN.footerNavItems[2]}</Link>
                            <Link to="/contact" className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.EN.footerNavItems[3]}</Link>
                          </div>
                          <a href="https://www.instagram.com/nihplodskincare/" target="_blank" className="inst-icon"><img src={inst} alt="ins"></img></a>
                          <div className='rights'>
                            {pageText.EN.rights}
                          </div>
                        </div>
                        
                      </div>
                    </CSSTransition>
                  )}
                </Route>
              ))}
              {/* <ScrollToTop><div className="rights-hidden">© 2025 NIHPLOD. ALL RIGHTS RESERVED.</div></ScrollToTop> */}
            </div>
        </div>
      </div>
      :
      <div>
         <div className={`cookie-policy ${cookies.get('cookie') === "accepted" ? "accepted" : ""}`}>
            <div class="cookie-policy-description"><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.FR.cookieTip) }}></span><Link to={{pathname: "/tac" ,anchor: "privacy-policy"}} className="cookie-policy-content" onClick={() => {viewPolicy()}}><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.FR.cookiePolicy) }}></span></Link>.</div><svg title="Accepter" className='cancel-icon' width="10" height="9" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {accept()}}><path d="M8.5 1.25 5 4.75l3.5 3.5M1 1.25l3.5 3.5L1 8.25"></path></svg>
          </div>
        <CartFR></CartFR>
          <div className='slideMask'></div>
          <div className="top-container">
            <div className='slideMask2'></div>
            <div className='top-mask'>
            <HeaderFR></HeaderFR>
              {routesFR.map(({ path, Component  }) => (
                <Route key={path} exact path={path}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      appear={true}
                      timeout={{
                        appear: 1500,
                        enter: 2000,
                        exit: 1200,
                      }}
                      classNames="page"
                      unmountOnExit
                    >
                      <div className="page">
                        <Component />
                        <div className='footer'>
                          <div className='footer-divider'></div>
                          <div className='footer-nav'>
                            <Link to="/faq" className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.FR.footerNavItems[1]}</Link>
                            <Link to={{pathname: "/tac" ,anchor: "term-and-condition"}} className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.FR.footerNavItems[0]}</Link>
                            <Link to="/login" className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.FR.footerNavItems[2]}</Link>
                            <Link to="/contact" className="footer-nav-item" onClick={()=>changeUrl()}>{pageText.FR.footerNavItems[3]}</Link>
                          </div>
                          <a href="https://www.instagram.com/nihplodskincare/" target="_blank" className="inst-icon"><img src={inst} alt="ins"></img></a>
                          <div className='rights'>
                            {pageText.FR.rights}
                          </div>
                        </div>
                        
                      </div>
                    </CSSTransition>
                  )}
                </Route>
              ))}
              {/* <ScrollToTop><div className="rights-hidden">© 2025 NIHPLOD. ALL RIGHTS RESERVED.</div></ScrollToTop> */}
            </div>
            </div>
      </div>
      }
    </Router>
    </div>

    );
  }
}

export default App;
