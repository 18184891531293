
import {Component} from 'react';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { accept, checkCookie, changeUrl} from '../common/changeUrl'
import logo from '../common/images/logo.png';
import { products } from './products';

const pageText = {
    navItmes: ["collections","discover","exclusive","cart","fr"],
  }
  

const showMask = () => {
  $(".cart-mask").fadeIn(500)
  jQuery(".cart-list").animate({
    right: '0'
  },500,"easeInOutExpo",function(){
    $(".cart-list").css("right",0)
  })
  $(".page").addClass("scrollStop")
}

var totals = 0
var carts = {"total": 0}
var cartsFinal = Object.values(products.EN)

const changeLang = () => {
  var currentlang = JSON.parse(sessionStorage.getItem("cart"))
  if (currentlang.lang === 'EN'){
    currentlang.lang = "FR"
    sessionStorage.setItem("cart",JSON.stringify(currentlang))
    window.location.reload();
  }
}

class Header extends Component {

  componentWillMount(){
    if(sessionStorage.getItem("cart") === null){
      var cartItems = []
      cartsFinal.map((item) => {
        cartItems.push(
          {
            id: item.id,
            num: 0
          }
        )
      })
      carts = {cartItems,"total" : 0,"lang":"EN"}
      sessionStorage.setItem("cart",JSON.stringify(carts))
  } 
    if (sessionStorage.getItem("cart") != null){
      totals = JSON.parse(sessionStorage.getItem("cart")).total
    }
  }
    
    componentDidMount() {
        window.addEventListener("setItemEvent", (e) => {
            if ((e.key = "cart")) {
                var _this = sessionStorage.getItem("cart");
 
                if (_this !== e.newValue) {
                    if (e.newValue) {
                        this.setState({
                            show: e.newValue
                        })
                    }
                    totals = JSON.parse(e.newValue).total
                }
            }
        })
    }
    
    componentWillUnmount(){
      
  }


 
  render() {
    return (
    <header className="App-header">
        <Link to="/" className="nav-item" onClick={()=>{changeUrl("home")}}><img src={logo} className="App-logo nihplodfont-light"  /></Link>
        <div className="title-lang">
          <Link to="/collection" className="nav-item collection" onClick={()=>{changeUrl("collection")}}>{pageText.navItmes[0]}</Link>
          <Link to="/discover" className="nav-item discover" onClick={()=>{changeUrl("discover")}}>{pageText.navItmes[1]}</Link>
          <Link to="/exclusive" className="nav-item exclusive" onClick={()=>{changeUrl("exclusive")}}>{pageText.navItmes[2]}</Link>
          <a href="javascript:void(0)" className="nav-item" onClick={() => showMask()}>{pageText.navItmes[3]}<span className='cart'>({sessionStorage.getItem("cart") === null ? 0 : totals})</span></a>
          <a href="javascript:void(0)" className="nav-item language" onClick={() => changeLang()}>{pageText.navItmes[4]}</a>
          
        </div>
      </header>
    );
  }
}
 
export default withRouter(Header);
