import {Component} from 'react';
import './index.less';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import { products } from '../../component/products';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { changeUrlMobile, hideMenuMobile } from '../../common/changeUrl';
import { hideMask } from '../../component/cartMobile';


const toggleCover = (name) => {
  jQuery(`#${name.short}`).animate({
    opacity: 0
  },100,"easeInCubic",function(){
    $(`#${name.short}`).attr('src',name.details[2])
    jQuery(`#${name.short}`).animate({
      opacity: 1
    },200)
  })
}

const toggleCover2= (name) => {
  jQuery(`#${name.short}`).animate({
    opacity: 0.5
  },100,"easeInCubic",function(){
    $(`#${name.short}`).attr('src',name.specCover)
    jQuery(`#${name.short}`).animate({
      opacity: 1
    },100)
  })
}

const viewMenu = () => {
 if($(`.product-item-chevron, .product-list`).hasClass('active')){
  $(".collection-menu-mask").fadeOut(500)
  $(`.product-item-chevron, .product-list`).toggleClass('active')
 } else if(!$(`.product-item-chevron, .product-list`).hasClass('active')){
  $(".collection-menu-mask").fadeIn(500)
  $(`.product-item-chevron, .product-list`).toggleClass('active')
 }
}

class Collections extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1200,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 900);
  }
  
  componentDidMount(){
    if(!$(".collection").hasClass('active')){
      $(".collection").addClass('active')
    }
    $(".exclusive").removeClass('active')
    $(".discover").removeClass('active')

    jQuery(".AppMobile .collection-menu").delay(1500).animate({
      opacity: 1
    },1000,"easeInCubic")

    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
  }
  
  render () {
    return (
      <div className="container collection">
        <div className='collection-menu'>
          <div className='product-item-wrapper all' onClick={() => viewMenu()}><a className='product-item ' href='javascript:void(0)'>SÉLECTION</a><div className='product-item-chevron'></div></div>
          <div className='product-list'>
          <div className='product-item-wrapper'><Link to={'/' + products.FR.b.url} onClick={() => changeUrlMobile()} className="product-item b">{products.FR.b.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.FR.f.url} onClick={() => changeUrlMobile()} className="product-item f">{products.FR.f.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.FR.g.url} onClick={() => changeUrlMobile()} className="product-item a">{products.FR.g.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.FR.c.url} onClick={() => changeUrlMobile()} className="product-item c">{products.FR.c.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.FR.e.url} onClick={() => changeUrlMobile()} className="product-item e">{products.FR.e.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.FR.k.url} onClick={() => changeUrlMobile()} className="product-item d">{products.FR.k.specName}</Link></div>
            {/* <div className='product-item-wrapper'><Link to={'/' + products.FR.l.url} onClick={() => changeUrlMobile()} className="product-item i">{products.FR.l.specName}</Link></div> */}
            <div className='product-item-wrapper'><Link to={'/' + products.FR.d.url} onClick={() => changeUrlMobile()} className="product-item d">{products.FR.d.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.FR.i.url} onClick={() => changeUrlMobile()} className="product-item i">{products.FR.i.specName}</Link></div>
          </div>
        </div>
        <div className='collection-menu-mask' onClick={() => viewMenu()}></div>
        <div className='collection-item'>
          <div className='section one'>
            
          <Link to={'/' + products.FR.e.url} onClick={() => changeUrlMobile()} className="items">
                <div className='item-cover'>
                  <img src={products.FR.e.specCover} alt={products.FR.e.specName} id={products.FR.e.short} style={{"object-position":" 50% 100%"}}></img>
                </div>
                <div className='item-info'>
                  <div className='item-info-name'>{products.FR.e.specName}</div>
                  <div className='item-info-spec'> 
                    <div className='item-info-volumn'>{products.FR.e.specIntro}</div>
                    
                    </div>
                </div>
              </Link>
              <Link to={'/' + products.FR.c.url} onClick={() => changeUrlMobile()} className="items">
                  <div className='item-cover'>
                    <img src={products.FR.c.specCover} alt={products.FR.c.specName} id={products.FR.c.short}></img>
                  </div>
                  <div className='item-info'>
                    <div className='item-info-name'>{products.FR.c.specName}</div>
                    <div className='item-info-spec'> 
                      <div className='item-info-volumn'>{products.FR.c.specIntro}</div>
                      
                      </div>
                  </div>
                </Link>
              <Link to={'/' + products.FR.d.url} onClick={() => changeUrlMobile()} className="items">
                <div className='item-cover' >
                  <img src={products.FR.d.specCover} alt={products.FR.d.specName} id={products.FR.d.short} style={{"object-position":" 50% 100%"}}></img>
                </div>
                <div className='item-info'>
                  <div className='item-info-name'>{products.FR.d.specName}</div>
                  <div className='item-info-spec'> 
                    <div className='item-info-volumn'>{products.FR.d.specIntro}</div>
                    
                    </div>
                </div>
              </Link>
            <Link to={'/' + products.FR.g.url} onClick={() => changeUrlMobile()} className="items">
              <div className='item-cover'>
                <img src={products.FR.g.specCover} alt={products.FR.g.specName} id={products.FR.g.short}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.FR.g.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.g.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
          </div>
          <div className='section two'>
            <div className='left'>
            </div>
            <Link to={'/' + products.FR.b.url} onClick={() => changeUrlMobile()} className="items">
              <div className='item-cover'>
                <img src={products.FR.b.specCover} alt={products.FR.b.specName} id={products.FR.b.short}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.FR.b.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.b.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
            
            <Link to={'/' + products.FR.k.url} onClick={() => changeUrlMobile()} className="items">
              <div className='item-cover' >
                <img src={products.FR.k.specCover} alt={products.FR.k.specName} id={products.FR.k.short} style={{"object-position":" 50% 50%"}}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.FR.k.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.k.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
            <div className='right'>
            <Link to={'/' + products.FR.f.url} onClick={() => changeUrlMobile()} className="items">
              <div className='item-cover' >
                <img src={products.FR.f.specCover} alt={products.FR.f.specName} id={products.FR.f.short} style={{"object-position":" 50% 100%"}}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.FR.f.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.f.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
            </div>
            <Link to={'/' + products.FR.l.url} onClick={() => changeUrlMobile()} className="items">
              <div className='item-cover' >
                <img src={products.FR.l.specCover} alt={products.FR.l.specName} id={products.FR.l.short} style={{"object-position":" 50% 0%"}}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.FR.l.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.l.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
          </div>
          <div className='section one'>
              
            <Link to={'/' + products.FR.i.url} onClick={() => changeUrlMobile()} className="items">
              <div className='item-cover'>
                <img src={products.FR.i.specCover} alt={products.FR.i.specName} id={products.FR.i.short} onMouseEnter={() => toggleCover(products.FR.i)} onMouseLeave={() => toggleCover2(products.FR.i)}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.FR.i.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.i.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Collections);
