
import './home.less';
import {Component} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { accept, checkCookie, changeUrl} from '../../common/changeUrl'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay , A11y,EffectFade } from 'swiper';
// Import Swiper styles
import 'swiper/css/bundle';

import vogue from '../../common/images/home/vogue.png'
import elle from '../../common/images/home/elle.png'
import bazaar from '../../common/images/home/bazaar.png'
import quote from '../../common/images/home/quote.png'

import popular1 from '../../common/images/home/serum1.jpg'
import popular2 from '../../common/images/home/facecream1.jpg'
import popular3 from '../../common/images/home/facescrub1.jpg'

import banner2 from '../../common/images/home/banner3.jpg'
import banner4 from '../../common/images/home/banner2.jpg'

import { hideMask } from '../../component/cart';
import { withRouter } from 'react-router';
import { products } from '../../component/products';

const pageText = {
  banner: {
    title: ["Elevate Your","Skincare Ritual", "SKINCARE", "EXPERIENCE","Indulge in","Luxurious Hydration","Discover our body skincare collection for ultimate rejuvenation"],
    button: `shop now`
  },
  popular: ["MOST","POPULAR"],
  magazine: {
    vogue: `
    <span class="upper-size">NIHPLOD</span>'s step by step facial that focuses on brightening and diminishing pigmentation whilst balancing the skin.
    `,
    elle:`
    <span class="upper-size none-logo">E</span>lle honors <span class="upper-size">NIHPLOD</span> with its second award. <span class="upper-size none-logo">O</span>ur <span class="upper-size none-logo">F</span>ace <span class="upper-size none-logo">C</span>ream has made the cut in the world's most esteemed grooming routine as <span class="upper-size none-logo">B</span>est “<span class="upper-size none-logo">S</span>plurge” <span class="upper-size none-logo">M</span>oisturizer.
    `,
    bazaar: `
    <span class="upper-size none-logo">W</span>ith the help of new generation liposome technology, <span class="upper-size">NIHPLOD</span> solves the problem that skin can't really absorb skincare products.
    `,
  },
  banner2: {
    title: ["MINIMALIST","SKINCARE","CARE"],
    content: [
      `<p><span class="upper-size-content none-logo">O</span>ur goal is to make your skin care routine easier and more efficient.</br><span class="upper-size-content none-logo">N</span>o complicated choices are needed, which makes it all safer and more enjoyable.</p>`],
    button: `<span class="upper-size-content none-logo">D</span>iscover more`
  },
  banner3: {
    content: `
    <div class='content-title'>LIPOSOME</br>TECHNOLOGIES</div>
    <div class='content-text'>Unlocking deeper skin nourishment for enhanced</br>hydration and effectiveness.</div>
    `
  },
  banner4: {
    title: ["OUR","STORY"],
    content: [
      `<span class="upper-size-content none-logo">T</span>he dolphin's skin has the magical ability to renew itself every two hours. <span class="upper-size-content none-logo">W</span>e draw our inspiration from that animal instinct of "time reversal".<br/></br><span class="upper-size-content none-logo">A</span>s time and space change, our skin faces various problems due to work pressure, bad habits and aging.`, 
      `<span class="upper-size-content none-logo">S</span>o we reversed the word "<span class="upper-size">DOLPHIN</span>", we got <span class="upper-size">NIHPLOD</span>.`,
      
    ],
    button: `<span class="upper-size-content none-logo">R</span>ead more`,
  },
  mostLoved: {
    content: "Avoid (unnecessary) complicated choices and make the skin care simpler and purer.",
    title: "MOST LOVED",
  }
}

const mouseOver = (e) => {
  $(`.maga-item-front.${e}`).fadeOut(200)
  $(`.maga-item-back.${e}`).fadeIn(200)
}

const mouseLeave = (e) => {
  $(`.maga-item-front.${e}`).fadeIn(200)
  $(`.maga-item-back.${e}`).fadeOut(200)
}

const recordy = () => {
  return $(window).scrollTop()
}

const switchBanner = () => {
  var loca = window.location.href
  if($(window).scrollTop() < $(window).height() && loca.substring(loca.length, loca.length - 1) === "/"){
     
    $(".top-mask .App-header").css('background-color','rgba(255,255,255,0.5)')
  } else {
    $(".top-mask .App-header").css('background-color','white')
     
  }
}

const toggleCover = (name) => {
  // jQuery(`#${name.short}`).animate({
  //   opacity: 0
  // },300,"easeInCubic",function(){
  //   $(`#${name.short}`).attr('src',name.details[2])
  //   jQuery(`#${name.short}`).animate({
  //     opacity: 1
  //   },300)
  // })
  $(`#${name.short}`).attr('src',name.details[2])
}

const toggleCover2= (name) => {
  // jQuery(`#${name.short}`).animate({
  //   opacity: 0.5
  // },300,"easeInCubic",function(){
  //   $(`#${name.short}`).attr('src',name.specCover)
  //   jQuery(`#${name.short}`).animate({
  //     opacity: 1
  //   },300)
  // })
  $(`#${name.short}`).attr('src',name.details[1])
}

const stopSwiper = (swiper) => {
  if(swiper.activeIndex === 5 ){
    swiper.autoplay.stop()
  }
}



class PageHome extends Component {
  
  componentWillMount() {
    setTimeout(() => {
      $(".top-mask .App-header").css('background-color','rgba(255,255,255,0.5)')
    },400)
    

    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })

    window.addEventListener("resize",switchBanner,false)
    window.addEventListener("scroll",switchBanner,false)
  }

  componentDidMount(){
    $(".collection").removeClass('active')
    $(".discover").removeClass('active')
    $(".exclusive").removeClass('active')
    window.history.scrollRestoration = 'manual'

    
    $("#swiper2")[0].swiper.autoplay.stop()
    $("#swiper3")[0].swiper.autoplay.stop()
    $("#swiper4")[0].swiper.autoplay.stop()

    $("#swiper2")[0].onmouseover = () => {
      $("#swiper2")[0].swiper.slideNext()
      $("#swiper2")[0].swiper.autoplay.start()
    }

    $("#swiper2")[0].onmouseleave = () => {
      $("#swiper2")[0].swiper.slideTo(5)
      $("#swiper2")[0].swiper.autoplay.stop()
    }

    $("#swiper3")[0].onmouseover = () => {
      $("#swiper3")[0].swiper.slideNext()
      $("#swiper3")[0].swiper.autoplay.start()
    }

    $("#swiper3")[0].onmouseleave = () => {
      $("#swiper3")[0].swiper.slideTo(5)
      $("#swiper3")[0].swiper.autoplay.stop()
    }

    $("#swiper4")[0].onmouseover = () => {
      $("#swiper4")[0].swiper.slideNext()
      $("#swiper4")[0].swiper.autoplay.start()
    }

    $("#swiper4")[0].onmouseleave = () => {
      $("#swiper4")[0].swiper.slideTo(5)
      $("#swiper4")[0].swiper.autoplay.stop()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
    changeUrl()
  }

  componentWillUnmount(){
    window.removeEventListener("resize",switchBanner,false)
    window.removeEventListener("scroll",switchBanner,false)
    setTimeout(() => {
      $(".top-mask .App-header").css('background-color','white')
    },900)
  }


  render (){
    return (
      <div className="container">
        <div className='banner'>
          <Swiper
              modules={[Navigation, Pagination, A11y,Autoplay ]}
              slidesPerView={1}
              speed={2000}
              navigation={{ 
                clickable: true
               }}
              autoplay={{delay: 3000}}
              loop={true}
            >
              <SwiperSlide>
                <div className='banner-slide'>
                <div className='slogan'>
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[0]}</p>
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[1]}</p>
                    {/* <p>{pageText.banner.title[4]}</p>
                    <p>{pageText.banner.title[5]}</p>
                    <p>{pageText.banner.title[6]}</p> */}
                    <Link to="/collection" className="find-out-more" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
                </div></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='banner-slide two'>
                <div className='slogan'>
                    {/* <p>{pageText.banner.title[0]}</p>
                    <p>{pageText.banner.title[1]}</p>
                    <p>{pageText.banner.title[2]}</p>
                    <p>{pageText.banner.title[3]}</p> */}
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[4]}</p>
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[5]}</p>
                    <p className='banner-context'>{pageText.banner.title[6]}</p>
                    <Link to="/collection" className="find-out-more" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
                </div></div>
              </SwiperSlide>
            </Swiper>
        </div>
        <div className='most-loved'>
          <div className='most-loved-title'>{pageText.mostLoved.title}</div>
          </div>
        <div className='most-popular'>
          
        <Link to={{pathname: "/face-cream" ,source: 'h'}} className='most-popular-wrapper' onClick={() => {changeUrl("face-cream")}}>
            <div className='most-popular-item' >
              <Swiper
              id="swiper2"
              modules={[Autoplay,EffectFade ]}
              slidesPerView={1}
              speed={1000}
              loop={true}
              effect="fade"
              autoplay={{delay:500}}
            >
              <SwiperSlide>
                <img src={products.EN.e.homeUse[0]} alt={products.EN.e.specName} id={products.EN.e.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.e.homeUse[1]} alt={products.EN.e.specName} id={products.EN.e.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.e.homeUse[2]} alt={products.EN.e.specName} id={products.EN.e.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.e.homeUse[3]} alt={products.EN.e.specName} id={products.EN.e.short} ></img>
              </SwiperSlide>
            </Swiper>
            </div>
            <div className='item-info'>
                <div className='item-info-name'>{products.EN.e.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.e.specIntro}</div>
                  
                  </div>
              </div>
          </Link>
          <Link to={{pathname: "/sunscreen" ,source: 'h'}} className='most-popular-wrapper' onClick={() => {changeUrl()}}>
            <div className='most-popular-item'>
            <Swiper
              id="swiper3"
              modules={[Autoplay ,EffectFade]}
              slidesPerView={1}
              speed={1000}
              loop={true}
              effect="fade"
              autoplay={{delay:500}}
            >
              <SwiperSlide>
                <img src={products.EN.d.homeUse[0]} alt={products.EN.d.specName} id={products.EN.d.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.d.homeUse[1]} alt={products.EN.d.specName} id={products.EN.d.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.d.homeUse[2]} alt={products.EN.d.specName} id={products.EN.d.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.d.homeUse[3]} alt={products.EN.d.specName} id={products.EN.d.short} ></img>
              </SwiperSlide>
            </Swiper>
            </div>
            <div className='item-info'>
                <div className='item-info-name'>{products.EN.d.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.d.specIntro}</div>
                  
                  </div>
              </div>
          </Link>
          <Link to={{pathname: "/gift-box-series" ,source: 'h'}} className='most-popular-wrapper' onClick={() => {changeUrl()}}>
            <div className='most-popular-item'>
            <Swiper
              id="swiper4"
              modules={[Autoplay ,EffectFade]}
              slidesPerView={1}
              speed={1000}
              loop={true}
              effect="fade"
              autoplay={{delay:500}}
            >
              <SwiperSlide>
                <img src={products.EN.i.homeUse[0]} alt={products.EN.i.specName} id={products.EN.i.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.i.homeUse[1]} alt={products.EN.i.specName} id={products.EN.i.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.i.homeUse[2]} alt={products.EN.i.specName} id={products.EN.i.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.EN.i.homeUse[3]} alt={products.EN.i.specName} id={products.EN.i.short} ></img>
              </SwiperSlide>
            </Swiper>
            </div>
            <div className='item-info'>
                <div className='item-info-name'>{products.EN.i.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.i.specIntro}</div>
                  
                  </div>
              </div>
          </Link>
        </div>

        <div className="view-all-products">
          <Link to="/collection" className="view-all-products-btn" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
        </div>

        <div className='skin-care-journey'>
          <div className='skin-care-journey-content left'>
            <div className='content-title'>
              <p>{pageText.banner2.title[0]}</p>
              <p>{pageText.banner2.title[1]}</p>
            </div>
            <div className='content-text'>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner2.content[0] }}></p>
            </div>
            <Link to="/collection" className="discover-more" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner2.button }}></Link>
          </div>
          <div className='banner2'>
            <img src={banner2}></img>
          </div>
        </div>

        
        <div className='banner3'>
          <div className='banner3-context' dangerouslySetInnerHTML={{ __html: pageText.banner3.content }}></div>
        </div>

      <div className='magazines'>
        <div className='maga-item' onMouseOver={() => {mouseOver('vogue')}} onMouseLeave={() => {mouseLeave('vogue')}}>
          <div className='maga-item-front vogue'>
            <img src={vogue}></img>
          </div>
          <div className='maga-item-back vogue'>
            <div className='quote-icon'>
              <img src={quote}></img>
            </div>
            <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.vogue }}>
            </div>
          </div>
        </div>
        <div className='maga-item' onMouseOver={() => {mouseOver('elle')}} onMouseLeave={() => {mouseLeave('elle')}}>
          <div className='maga-item-front elle'>
            <img src={elle}></img>
          </div>
          <div className='maga-item-back elle'>
          < div className='quote-icon'>
              <img src={quote}></img>
            </div>
            <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.elle }}></div>
          </div>
        </div>
        <div className='maga-item' onMouseOver={() => {mouseOver('bazaar')}} onMouseLeave={() => {mouseLeave('bazaar')}}>
          <div className='maga-item-front bazaar'>
            <img src={bazaar}></img>
          </div>
          <div className='maga-item-back bazaar'>
            <div className='quote-icon'>
              <img src={quote}></img>
            </div>
            <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.bazaar }}></div>
          </div>
        </div>
      </div>


        <div className='content-section'>
          <div className='banner4'>
            <img src={banner4}></img>
          </div>
          <div className='content-section-content right'>
            <div className='content-title'>
              <p>{pageText.banner4.title[0]}</p>
              <p>{pageText.banner4.title[1]}</p>
            </div>
            <div className='content-text'>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner4.content[0] }}></p>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner4.content[1] }}></p>
            </div>
            <Link to="/discover" className="discover-more" onClick={() => {changeUrl("discover")}} dangerouslySetInnerHTML={{ __html: pageText.banner4.button }}></Link>
          </div>
        </div>

      </div>
      
    );
  }
}

export default withRouter(PageHome);
