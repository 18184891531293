import React, { useState } from 'react';
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { checkPolicy } from '../../common/changeUrl'
import * as Request from '../../common/request';
import { upperLaundryTac } from '../../common/utils'
import { hideMask } from '../../component/cart';
import { changeUrl } from '../../common/changeUrl';

const pageText = {
    menu: ["MEMBERSHIP"],
    intro:  `
    <div class="text">Enjoy customized, once - in - a - lifetime activities and treatments, from skin beauty and fashion to art, drama and travel.</div>
    <div class="text"><span class="brand-name">NIHPLOD</span> membership is by invitation only.</div>
    <div class="text">If you are interested in being invited to become a member of <span class="brand-name">NIHPLOD</span>, please provide the following information:</div>
    `,
    text: {
        MEMBERSHIP: 'MEMBERSHIP',
        SUBMIT: 'SUBMIT',
        FULL_NAME: 'FULL NAME',
        EMAILADDRESS: 'EMAIL ADDRESS',
        CONTACTNUM: 'CONTACT NUMBER',
        INVITATIONCODE: 'INVITATION CODE',
        BUTTON:"SUBMIT",
        ERR_FULL_NAME: 'Full Name is required.',
        ERR_EMAILADDRESS: 'Email Address is required.',
        ERR_CONTACTNUM: 'Contact Number is required.',
        ERR_INVITATIONCODE: 'Invitation Code is required.',
        ERR_EMAIL_FORMAT: 'Wrong email format.',
        tipTxt: `Invalid Invitation Code`,
        SUCCESSTIP: `Submitted Successfully.<br />We will contact you soon.`
    },
    tips:`<p>Please learn about the relevant policies of the invitation code at the special partner spa salon or our special dealer.</p>`
}


const FormBody = (props) => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ contact, setContact ] = useState('');
    const [ invicode, setInvitation ] = useState('');
    
    const login7 = () => {
        const EMAIL_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        $(".form-btn").addClass("disabled")
        if ($(".form-name").val() === ""){
            $(".blank-name").fadeIn(300,function () {
                setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled");
                    $(".blank-name").fadeOut(300)
                },1000
                )
            });
        } else if ($(".form-email").val() === ""){
            $(".blank-email").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-email").fadeOut(300)
                },1000
            )
            });
        } else if(!EMAIL_PATTERN.test(email)){
            $(".wrong-email").fadeIn(300,function () {
              setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".wrong-email").fadeOut(300)
                },1000
              )
            });
          } else if ($(".form-contact").val() === ""){
            $(".blank-contact").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-contact").fadeOut(300)
                },1000
            )
            });
        }  else if ($(".form-invicode").val() === ""){
            $(".blank-invicode").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-invicode").fadeOut(300)
                },1000
            )
            });
        } else {
            Request.post({
            url: '/u/reg',
            data: {
                name,
                email,
                contact,
                invicode,
                type:1,
            }
            }).then(res => {
            $(".blank-acct").delay(1500).fadeIn(300,function () {
                setTimeout(
                    function () {
                        $(".form-btn").removeClass("disabled")
                        $(".blank-acct").fadeOut(300)
                    },3000
                )
            });
        });
      }
    };

    return (
        <div className='form-wrapper'>
            <div className='input-wrapper'>
                <input placeholder={pageText.text.FULL_NAME} onChange={e => {
                        setName(e.target.value);
                    }} className='form-name'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.EMAILADDRESS} onChange={e => {
                        setEmail(e.target.value);
                    }} className='form-email'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.CONTACTNUM} onChange={e => {
                        setContact(e.target.value);
                    }} className='form-contact'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.INVITATIONCODE} onChange={e => {
                        setInvitation(e.target.value);
                    }} className='form-invicode'></input>
                </div>
            <div className='tips' dangerouslySetInnerHTML={{ __html: upperLaundryTac(pageText.tips) }}></div>
            <div className='form-btn' onClick={() => login7()}>{pageText.text.SUBMIT}</div>
        </div>
    )
}


class PageModel extends Component {
    componentWillMount() {
        jQuery(".slideMask").animate({
        left: '-100vw'
        },1500,"easeInCubic",function(){
        $(".slideMask").css("left","100vw")
        })
    }
  
    componentDidMount(){
        $(".collection").removeClass('active')
        $(".exclusive").removeClass('active')
        $(".discover").removeClass('active')
        window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
      changeUrl()
    }
    
    
    render() {
        return (
            <div className="container tac forms">
                <div className='blank-input blank-name'>{pageText.text.ERR_FULL_NAME}</div>
                <div className='blank-input blank-email'>{pageText.text.ERR_EMAILADDRESS}</div>
                <div className='blank-input blank-contact'>{pageText.text.ERR_CONTACTNUM}</div>
                <div className='blank-input blank-invicode'>{pageText.text.ERR_INVITATIONCODE}</div>
                <div className='blank-input blank-acct'>{pageText.text.tipTxt}</div>
                <div className='blank-input wrong-email'>{pageText.text.ERR_EMAIL_FORMAT}</div>
                
                <div className='tac-menu'></div>
                <div className='tac-content'>
                    <div className='tac-content-section'>
                        <div className='tac-title'>{pageText.menu[0]}</div>
                        <div className='tac-context' dangerouslySetInnerHTML={{ __html: upperLaundryTac(pageText.intro) }} style={{marginTop:'2rem'}}></div>
                    </div>
                    <FormBody></FormBody>
                </div>
            </div>
        );
    }
}
 
export default withRouter(PageModel);