
import './home.less';
import {Component} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { accept, checkCookie, changeUrl} from '../../common/changeUrl'
import { upperLaundry } from '../../common/utils';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay , A11y,EffectFade } from 'swiper';
// Import Swiper styles
import 'swiper/css/bundle';

import vogue from '../../common/images/home/vogue.png'
import elle from '../../common/images/home/elle.png'
import bazaar from '../../common/images/home/bazaar.png'
import quote from '../../common/images/home/quote.png'

import popular1 from '../../common/images/home/serum1.jpg'
import popular2 from '../../common/images/home/facecream1.jpg'
import popular3 from '../../common/images/home/facescrub1.jpg'

import banner2 from '../../common/images/home/banner3.jpg'
import banner4 from '../../common/images/home/banner2.jpg'
import { hideMask } from '../../component/cart';
import { withRouter } from 'react-router';
import { products } from '../../component/products';

const pageText = {
  banner: {
    title: ["Elevate Your","Skincare Ritual", "SKINCARE", "EXPERIENCE","Indulge in","Luxurious Hydration","Discover our body skincare collection for ultimate rejuvenation"],
    button: `shop now`
  },
  popular: ["LE PLUS","POPULAIRE"],
  magazine: {
    vogue: `
    <span class="upper-size">NIHPLOD</span>'s step by step facial that focuses on brightening and diminishing pigmentation whilst balancing the skin.
    `,
    elle:`
    <span class="upper-size none-logo">E</span>lle honors <span class="upper-size">NIHPLOD</span> with its second award. <span class="upper-size none-logo">O</span>ur <span class="upper-size none-logo">F</span>ace <span class="upper-size none-logo">C</span>ream has made the cut in the world's most esteemed grooming routine as <span class="upper-size none-logo">B</span>est “<span class="upper-size none-logo">S</span>plurge” <span class="upper-size none-logo">M</span>oisturizer.
    `,
    bazaar: `
    <span class="upper-size none-logo">W</span>ith the help of new generation liposome technology, <span class="upper-size">NIHPLOD</span> solves the problem that skin can't really absorb skincare products.
    `,
  },
  banner2: {
    title: ["SOINS DE","LA PEAU","MINIMALISTES"],
    content: [
      `<span class="upper-size-content none-logo">N</span>otre objectif est de rendre votre routine de soins de la peau plus facile et plus efficace.`,
    `<span class="upper-size-content none-logo">A</span>ucun choix compliqué n'est nécessaire, ce qui rend le tout plus simple et agréable.`],
    button: `<span class="upper-size-content none-logo">D</span>écouvrir plus`
  },
  banner3: {
    content: `
    <div class='content-title'>LIPOSOME</br>TECHNOLOGIES</div>
    <div class='content-text'>Unlocking deeper skin nourishment for enhanced</br>hydration and effectiveness.</div>
    `
  },
  banner4: {
    title: ["NOTRE","HISTOIRE"],
    content: [
      `<p>La peau du dauphin a la capacité magique de se renouveler toutes les deux heures. Notre source d’inspiration est cet instinct animal de ‘l’inversion du temps’.</p>
      <p>La peau sera confrontée à divers problèmes causés par le temps, l’environment, la pression au travail et les mauvaises habitudes.</p>
      <p>Nous avons ainsi inversé les lettres du mot anglais "DOLPHIN", et avons obtenu NIHPLOD.</p>`
    ],
    button: `<span class="upper-size-content none-logo">D</span>écouvrir plus`
  },
  mostLoved: {
    content: "Avoid (unnecessary) complicated choices and make the skin care simpler and purer.",
    title: "LES PLUS AIMÉS",
  }

}

const mouseOver = (e) => {
  $(`.maga-item-front.${e}`).fadeOut(200)
  $(`.maga-item-back.${e}`).fadeIn(200)
}

const mouseLeave = (e) => {
  $(`.maga-item-front.${e}`).fadeIn(200)
  $(`.maga-item-back.${e}`).fadeOut(200)
}

const switchBanner = () => {
  var loca = window.location.href
  if($(window).scrollTop() < $(window).height() && loca.substring(loca.length, loca.length - 1) === "/"){
     
    $(".top-mask .App-header").css('background-color','rgba(255,255,255,0.5)')
  } else {
    $(".top-mask .App-header").css('background-color','white')
     
  }
}

const swiperStart = () => {
  console.log($("#swiper2"))
}

const stopSwiper = (swiper) => {
  if(swiper.activeIndex === 5 ){
    swiper.autoplay.stop()
  }
}


class PageHome extends Component {
  
  componentWillMount() {
    setTimeout(() => {
      $(".top-mask .App-header").css('background-color','rgba(255,255,255,0.5)')
    },400)

    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })

    window.addEventListener("resize",switchBanner,false)
    window.addEventListener("scroll",switchBanner,false)
  }

  componentDidMount(){
    $(".collection").removeClass('active')
    $(".discover").removeClass('active')
    $(".exclusive").removeClass('active')
    window.history.scrollRestoration = 'manual'

    $("#swiper2")[0].onmouseover = () => {
      $("#swiper2")[0].swiper.autoplay.start()
    }

    // $("#swiper2")[0].onmouseleave = () => {
    //   $("#swiper2")[0].swiper.autoplay.stop()
    // }

    $("#swiper3")[0].onmouseover = () => {
      $("#swiper3")[0].swiper.autoplay.start()
    }

    // $("#swiper3")[0].onmouseleave = () => {
    //   $("#swiper3")[0].swiper.autoplay.stop()
    // }

    $("#swiper4")[0].onmouseover = () => {
      $("#swiper4")[0].swiper.autoplay.start()
    }

    // $("#swiper4")[0].onmouseleave = () => {
    //   $("#swiper4")[0].swiper.autoplay.stop()
    // }

  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
    changeUrl()
  }

  componentWillUnmount(){
    window.removeEventListener("resize",switchBanner,false)
    window.removeEventListener("scroll",switchBanner,false)
    setTimeout(() => {
      $(".top-mask .App-header").css('background-color','white')
    },900)
  }


  render (){
    return (
      <div className="container">
        <div className='banner'>
          <Swiper
              modules={[Navigation, Pagination, A11y,Autoplay ]}
              slidesPerView={1}
              speed={2000}
              navigation={{ 
                clickable: true
               }}
              loop={true}
              autoplay={{delay: 3000}}
            >
              <SwiperSlide>
                <div className='banner-slide'>
                <div className='slogan'>
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[0]}</p>
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[1]}</p>
                    {/* <p>{pageText.banner.title[4]}</p>
                    <p>{pageText.banner.title[5]}</p>
                    <p>{pageText.banner.title[6]}</p> */}
                    <Link to="/collection" className="find-out-more" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
                </div></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='banner-slide two'>
                <div className='slogan'>
                    {/* <p>{pageText.banner.title[0]}</p>
                    <p>{pageText.banner.title[1]}</p>
                    <p>{pageText.banner.title[2]}</p>
                    <p>{pageText.banner.title[3]}</p> */}
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[4]}</p>
                    <p style={{"margin-left": "-4px"}}>{pageText.banner.title[5]}</p>
                    <p className='banner-context'>{pageText.banner.title[6]}</p>
                    <Link to="/collection" className="find-out-more" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
                </div></div>
              </SwiperSlide>
            </Swiper>
            </div>

            <div className='most-loved'>
          <div className='most-loved-title'>{pageText.mostLoved.title}</div>
          </div>
        <div className='most-popular'>
          
          <Link to={{pathname: "/face-cream" ,source: 'h'}} className='most-popular-wrapper' onClick={() => {changeUrl("face-cream")}}>
            <div className='most-popular-item' >
              <Swiper
              id="swiper2"
              modules={[Autoplay,EffectFade ]}
              slidesPerView={1}
              speed={2000}
              loop={true}
              effect="fade"
              onSlideChange={(swiper) => {stopSwiper(swiper)}}
            >
              <SwiperSlide>
                <img src={products.FR.e.homeUse[0]} alt={products.FR.e.specName} id={products.FR.e.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.e.homeUse[1]} alt={products.FR.e.specName} id={products.FR.e.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.e.homeUse[2]} alt={products.FR.e.specName} id={products.FR.e.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.e.homeUse[3]} alt={products.FR.e.specName} id={products.FR.e.short} ></img>
              </SwiperSlide>
            </Swiper>
            </div>
            <div className='item-info'>
                <div className='item-info-name'>{products.FR.e.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.e.specIntro}</div>
                  
                  </div>
              </div>
          </Link>
          <Link to={{pathname: "/sunscreen" ,source: 'h'}} className='most-popular-wrapper' onClick={() => {changeUrl()}}>
            <div className='most-popular-item'>
            <Swiper
              id="swiper3"
              modules={[Autoplay ,EffectFade]}
              slidesPerView={1}
              speed={2000}
              loop={true}
              effect="fade"
              onSlideChange={(swiper) => {stopSwiper(swiper)}}
            >
              <SwiperSlide>
                <img src={products.FR.d.homeUse[0]} alt={products.FR.d.specName} id={products.FR.d.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.d.homeUse[1]} alt={products.FR.d.specName} id={products.FR.d.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.d.homeUse[2]} alt={products.FR.d.specName} id={products.FR.d.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.d.homeUse[3]} alt={products.FR.d.specName} id={products.FR.d.short} ></img>
              </SwiperSlide>
            </Swiper>
            </div>
            <div className='item-info'>
                <div className='item-info-name'>{products.FR.d.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.d.specIntro}</div>
                  
                  </div>
              </div>
          </Link>
          <Link to={{pathname: "/gift-box-series" ,source: 'h'}} className='most-popular-wrapper' onClick={() => {changeUrl()}}>
            <div className='most-popular-item'>
            <Swiper
              id="swiper4"
              modules={[Autoplay ,EffectFade]}
              slidesPerView={1}
              speed={2000}
              loop={true}
              effect="fade"
              onSlideChange={(swiper) => {stopSwiper(swiper)}}
            >
              <SwiperSlide>
                <img src={products.FR.i.homeUse[0]} alt={products.FR.i.specName} id={products.FR.i.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.i.homeUse[1]} alt={products.FR.i.specName} id={products.FR.i.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.i.homeUse[2]} alt={products.FR.i.specName} id={products.FR.i.short} ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img src={products.FR.i.homeUse[3]} alt={products.FR.i.specName} id={products.FR.i.short} ></img>
              </SwiperSlide>
            </Swiper>
            </div>
            <div className='item-info'>
                <div className='item-info-name'>{products.FR.i.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.FR.i.specIntro}</div>
                  
                  </div>
              </div>
          </Link>
        </div>

        <div className="view-all-products">
          <Link to="/collection" className="view-all-products-btn" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner.button }}></Link>
        </div>

        <div className='skin-care-journey'>
          <div className='skin-care-journey-content left'>
            <div className='content-title'>
              <p>{pageText.banner2.title[0]}</p>
              <p>{pageText.banner2.title[1]}</p>
            </div>
            <div className='content-text'>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner2.content[0] }}></p>
            </div>
            <Link to="/collection" className="discover-more" onClick={() => {changeUrl("collection")}} dangerouslySetInnerHTML={{ __html: pageText.banner2.button }}></Link>
          </div>
          <div className='banner2'>
            <img src={banner2}></img>
          </div>
        </div>

        
        <div className='banner3'>
          {/* <div className='banner3-context' dangerouslySetInnerHTML={{ __html: pageText.banner3.content }}></div> */}
        </div>

      <div className='magazines'>
        <div className='maga-item' onMouseOver={() => {mouseOver('vogue')}} onMouseLeave={() => {mouseLeave('vogue')}}>
          <div className='maga-item-front vogue'>
            <img src={vogue}></img>
          </div>
          <div className='maga-item-back vogue'>
            <div className='quote-icon'>
              <img src={quote}></img>
            </div>
            <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.vogue }}>
            </div>
          </div>
        </div>
        <div className='maga-item' onMouseOver={() => {mouseOver('elle')}} onMouseLeave={() => {mouseLeave('elle')}}>
          <div className='maga-item-front elle'>
            <img src={elle}></img>
          </div>
          <div className='maga-item-back elle'>
          < div className='quote-icon'>
              <img src={quote}></img>
            </div>
            <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.elle }}></div>
          </div>
        </div>
        <div className='maga-item' onMouseOver={() => {mouseOver('bazaar')}} onMouseLeave={() => {mouseLeave('bazaar')}}>
          <div className='maga-item-front bazaar'>
            <img src={bazaar}></img>
          </div>
          <div className='maga-item-back bazaar'>
            <div className='quote-icon'>
              <img src={quote}></img>
            </div>
            <div className='quote-context' dangerouslySetInnerHTML={{ __html: pageText.magazine.bazaar }}></div>
          </div>
        </div>
      </div>


        <div className='content-section'>
          <div className='banner4'>
            <img src={banner4}></img>
          </div>
          <div className='content-section-content right'>
            <div className='content-title'>
              <p>{pageText.banner4.title[0]}</p>
              <p>{pageText.banner4.title[1]}</p>
            </div>
            <div className='content-text'>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner4.content[0] }}></p>
              <p dangerouslySetInnerHTML={{ __html: pageText.banner4.content[1] }}></p>
            </div>
            <Link to="/discover" className="discover-more" onClick={() => {changeUrl("discover")}} dangerouslySetInnerHTML={{ __html: pageText.banner4.button }}></Link>
          </div>
        </div>

      </div>
      
    );
  }
}

export default PageHome;
