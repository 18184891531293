import {Component} from 'react';
import './index.less';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import { products } from '../../component/products';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { hideMask } from '../../component/cart';
import { changeUrl } from '../../common/changeUrl';

const toggleCover = (name) => {
  // jQuery(`#${name.short}`).animate({
  //   opacity: 0
  // },300,"easeInCubic",function(){
  //   $(`#${name.short}`).attr('src',name.details[2])
  //   jQuery(`#${name.short}`).animate({
  //     opacity: 1
  //   },300)
  // })
  $(`#${name.short}`).attr('src',name.details[2])
}

const toggleCover2= (name) => {
  // jQuery(`#${name.short}`).animate({
  //   opacity: 0.5
  // },300,"easeInCubic",function(){
  //   $(`#${name.short}`).attr('src',name.specCover)
  //   jQuery(`#${name.short}`).animate({
  //     opacity: 1
  //   },300)
  // })
  $(`#${name.short}`).attr('src',name.specCover)
}

class Collections extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
    
  }
  
  componentDidMount(){
    if(!$(".collection").hasClass('active')){
      $(".collection").addClass('active')
    }
    $(".exclusive").removeClass('active')
    $(".discover").removeClass('active')
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
    changeUrl()
  }

  
  
  render () {
    return (
      <div className="container collection">
        <div className='collection-menu'>
          <div className='product-item-wrapper'><div className='all product-item active'>ALL</div></div>
          <div className='product-list'>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.b.url} className="product-item b">{products.EN.b.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.f.url} className="product-item f">{products.EN.f.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.g.url} className="product-item a">{products.EN.g.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.c.url} className="product-item c">{products.EN.c.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.e.url} className="product-item e">{products.EN.e.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.k.url} className="product-item c">{products.EN.k.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.d.url} className="product-item d">{products.EN.d.specName}</Link></div><div className='product-item-wrapper'><Link to={'/' + products.EN.l.url} className="product-item i">{products.EN.l.specName}</Link></div>
            <div className='product-item-wrapper'><Link to={'/' + products.EN.i.url} className="product-item i">{products.EN.i.specName}</Link></div>
            
          </div>
        </div>
        <div className='collection-item'>
          <div className='section one'>
            <Link to={'/' + products.EN.e.url} className="items">
              <div className='item-cover'>
                <img src={products.EN.e.specCover} alt={products.EN.e.specName} id={products.EN.e.short} onMouseEnter={() => toggleCover(products.EN.e)} onMouseLeave={() => toggleCover2(products.EN.e)}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.EN.e.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.e.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
              <Link to={'/' + products.EN.c.url} className="items">
                  <div className='item-cover'>
                    <img src={products.EN.c.specCover} alt={products.EN.c.specName} id={products.EN.c.short} onMouseEnter={() => toggleCover(products.EN.c)} onMouseLeave={() => toggleCover2(products.EN.c)}></img>
                  </div>
                  <div className='item-info'>
                    <div className='item-info-name'>{products.EN.c.specName}</div>
                    <div className='item-info-spec'> 
                      <div className='item-info-volumn'>{products.EN.c.specIntro}</div>
                      
                      </div>
                  </div>
                </Link>
              <Link to={'/' + products.EN.d.url} className="items">
                <div className='item-cover'>
                  <img src={products.EN.d.specCover} alt={products.EN.d.specName} id={products.EN.d.short} onMouseEnter={() => toggleCover(products.EN.d)} onMouseLeave={() => toggleCover2(products.EN.d)}></img>
                </div>
                <div className='item-info'>
                  <div className='item-info-name'>{products.EN.d.specName}</div>
                  <div className='item-info-spec'> 
                    <div className='item-info-volumn'>{products.EN.d.specIntro}</div>
                    
                    </div>
                </div>
              </Link>
          </div>
          <div className='section two'>
            <div className='left'>
            <Link to={'/' + products.EN.g.url} className="items">
              <div className='item-cover'>
                <img src={products.EN.g.specCover} alt={products.EN.g.specName} id={products.EN.g.short} onMouseEnter={() => toggleCover(products.EN.g)} onMouseLeave={() => toggleCover2(products.EN.g)}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.EN.g.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.g.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
            <Link to={'/' + products.EN.b.url} className="items">
              <div className='item-cover'>
                <img src={products.EN.b.specCover} alt={products.EN.b.specName} id={products.EN.b.short} onMouseEnter={() => toggleCover(products.EN.b)} onMouseLeave={() => toggleCover2(products.EN.b)} style={{"object-position":"50% 70%","scale":"120%"}}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.EN.b.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.b.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
            {/* <Link to={'/' + products.EN.a.url} className="items">
              <div className='item-cover'>
                <img src={products.EN.a.specCover} alt={products.EN.a.specName} id={products.EN.a.short} onMouseEnter={() => toggleCover(products.EN.a)} onMouseLeave={() => toggleCover2(products.EN.a)}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.EN.a.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.a.specIntro}</div>
                  
                  </div>
              </div>
            </Link> */}
            </div>
            <div className='right'>
              <Link to={'/' + products.EN.k.url} className="items">
                  <div className='item-cover'>
                    <img src={products.EN.k.specCover} alt={products.EN.k.specName} id={products.EN.k.short} onMouseEnter={() => toggleCover(products.EN.k)} onMouseLeave={() => toggleCover2(products.EN.k)}></img>
                  </div>
                  <div className='item-info'>
                    <div className='item-info-name'>{products.EN.k.specName}</div>
                    <div className='item-info-spec'> 
                      <div className='item-info-volumn'>{products.EN.k.specIntro}</div>
                      
                      </div>
                  </div>
                </Link>
            </div>
          </div>
          <div className='section one last'>
            
            <Link to={'/' + products.EN.f.url} className="items">
                <div className='item-cover'>
                  <img src={products.EN.f.specCover} alt={products.EN.f.specName} id={products.EN.f.short} onMouseEnter={() => toggleCover(products.EN.f)} onMouseLeave={() => toggleCover2(products.EN.f)}></img>
                </div>
                <div className='item-info'>
                  <div className='item-info-name'>{products.EN.f.specName}</div>
                  <div className='item-info-spec'> 
                    <div className='item-info-volumn'>{products.EN.f.specIntro}</div>
                    
                    </div>
                </div>
              </Link>
              
            
            <Link to={'/' + products.EN.l.url} className="items">
                <div className='item-cover'>
                  <img src={products.EN.l.specCover} alt={products.EN.l.specName} id={products.EN.l.short} onMouseEnter={() => toggleCover(products.EN.l)} onMouseLeave={() => toggleCover2(products.EN.l)}></img>
                </div>
                <div className='item-info'>
                  <div className='item-info-name'>{products.EN.l.specName}</div>
                  <div className='item-info-spec'> 
                    <div className='item-info-volumn'>{products.EN.l.specIntro}</div>
                    
                    </div>
                </div>
              </Link>
              
            <Link to={'/' + products.EN.i.url} className="items">
              <div className='item-cover'>
                <img src={products.EN.i.specCover} alt={products.EN.i.specName} id={products.EN.i.short} onMouseEnter={() => toggleCover(products.EN.i)} onMouseLeave={() => toggleCover2(products.EN.i)}></img>
              </div>
              <div className='item-info'>
                <div className='item-info-name'>{products.EN.i.specName}</div>
                <div className='item-info-spec'> 
                  <div className='item-info-volumn'>{products.EN.i.specIntro}</div>
                  
                  </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Collections);
