import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import {Component} from 'react';
import './AppMobile.less';
import MobileHome from './mobile/home';
import MobileCollections from './mobile/collection';
import MobileDiscover from './mobile/discover';
import MobileExclusive from './mobile/exclusive';

import MobileTac from './mobile/botnav/tac';
import MobileFaq from './mobile/botnav/faq';
import MobileMembership from './mobile/botnav/membership';
import MobileContact from './mobile/botnav/contact';
import MobileLogin from './mobile/botnav/login';

import MobileFRHome from './mobileFR/home';
import MobileFRCollections from './mobileFR/collection';
import MobileFRDiscover from './mobileFR/discover';
import MobileFRExclusive from './mobileFR/exclusive';

import MobileFRTac from './mobileFR/botnav/tac';
import MobileFRFaq from './mobileFR/botnav/faq';
import MobileFRMembership from './mobileFR/botnav/membership';
import MobileFRContact from './mobileFR/botnav/contact';
import MobileFRLogin from './mobileFR/botnav/login';


import inst from './common/images/instgram.png';
import { CSSTransition } from 'react-transition-group';
import $ from 'jquery';
import { acceptMobile, checkCookieMobile, changeUrlMobile,checkPolicy} from './common/changeUrl';
import Cookies from 'universal-cookie';
import ScrollToTop from "./component/scroll";
import Header from './component/headerMobile';
import CartMobile from './component/cartMobile';

import HeaderMobileFR from './component/headerMobileFR';
import CartMobileFR from './component/cartMobileFR';

import { products } from './component/products';

import MobileFRCleanser from './mobileFR/products/cleanser';
import MobileFRCream from './mobileFR/products/cream';
import MobileFRMask from './mobileFR/products/mask';
import MobileFRSerum from './mobileFR/products/serum';
import MobileFRScrub from './mobileFR/products/scrub';
import MobileFRSunscreen from './mobileFR/products/sunscreen';
import MobileFRGiftBox from './mobileFR/products/giftbox2';
import MobileFRHandcream from './mobileFR/products/handcream';
import MobileFRBodylotion from './mobileFR/products/bodylotion';

import MobileCleanser from './mobile/products/cleanser';
import MobileCream from './mobile/products/cream';
import MobileMask from './mobile/products/mask';
import MobileSerum from './mobile/products/serum';
import MobileScrub from './mobile/products/scrub';
import MobileSunscreen from './mobile/products/sunscreen';
import MobilePageGiftBox from './mobile/products/giftbox2';
import MobileHandcream from './mobile/products/handcream';
import MobileBodylotion from './mobile/products/bodylotion';

import { upperLaundryCookie } from './common/utils';

import * as Request from './common/request';

$('html').animate({scrollTop: '0px'})
const cookies = new Cookies();



const routes = [
  { path: '/', name: 'Home', Component : MobileHome},
  { path: '/collection', name: 'Collections', Component : MobileCollections },
  { path: '/discover', name: 'Discover', Component : MobileDiscover },
  { path: '/exclusive', name: 'Exclusive', Component : MobileExclusive },
  { path: '/' + products.EN.g.url, name: products.EN.g.specName, Component : MobileCleanser},
  { path: '/' + products.EN.b.url, name: products.EN.b.specName,Component : MobileCream},
  { path: '/' + products.EN.c.url, name: products.EN.c.specName, Component : MobileSerum},
  { path: '/' + products.EN.d.url, name: products.EN.d.specName, Component : MobileSunscreen},
  { path: '/' + products.EN.e.url, name: products.EN.e.specName, Component : MobileMask},
  { path: '/' + products.EN.f.url, name: products.EN.f.specName, Component : MobileScrub},
  { path: '/' + products.EN.i.url, name: products.EN.i.specName, Component : MobilePageGiftBox},
  { path: '/' + products.EN.k.url, name: products.EN.k.specName, Component : MobileHandcream},
  { path: '/' + products.EN.l.url, name: products.EN.l.specName, Component : MobileBodylotion},
  { path: '/tac', name: 'TAC', Component : MobileTac },
  { path: '/faq', name: 'FAQ', Component : MobileFaq },
  { path: '/membership', name: 'Membership', Component : MobileMembership },
  { path: '/contact', name: 'Contact', Component : MobileContact },
  { path: '/login', name: 'Login', Component : MobileLogin },
]

const routesFR = [
  
  { path: '/', name: 'Home', Component : MobileFRHome},
  { path: '/collection', name: 'Collections', Component : MobileFRCollections },
  { path: '/discover', name: 'Discover', Component : MobileFRDiscover },
  { path: '/exclusive', name: 'Exclusive', Component : MobileFRExclusive },
  { path: '/' + products.FR.g.url, name: products.FR.g.specName, Component : MobileFRCleanser},
  { path: '/' + products.FR.b.url, name: products.FR.b.specName,Component : MobileFRCream},
  { path: '/' + products.FR.c.url, name: products.FR.c.specName, Component : MobileFRSerum},
  { path: '/' + products.FR.d.url, name: products.FR.d.specName, Component : MobileFRSunscreen},
  { path: '/' + products.FR.e.url, name: products.FR.e.specName, Component : MobileFRMask},
  { path: '/' + products.FR.f.url, name: products.FR.f.specName, Component : MobileFRScrub},
  { path: '/' + products.FR.i.url, name: products.FR.i.specName, Component : MobileFRGiftBox},
  { path: '/' + products.FR.k.url, name: products.FR.k.specName, Component : MobileFRHandcream},
  { path: '/' + products.FR.l.url, name: products.FR.l.specName, Component : MobileFRBodylotion},
  { path: '/tac', name: 'TAC', Component : MobileFRTac },
  { path: '/faq', name: 'FAQ', Component : MobileFRFaq },
  { path: '/membership', name: 'Membership', Component : MobileFRMembership },
  { path: '/contact', name: 'Contact', Component : MobileFRContact },
  { path: '/login', name: 'Login', Component : MobileFRLogin },
]

var lang = 'EN'

const pageText = {
  EN:{
    cookieTip: `We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. <br/>We also share information about your use of our site with our social media, advertising and analytics partners.`,
    cookiePolicy:"Cookie policy",
    footerNavItems:["tac","faq","membership","contact","language"],
    rights: "© 2025 NIHPLOD. ALL RIGHTS RESERVED.",
  },
  FR:{
    cookieTip: `Nous utilisons des cookies pour personnaliser le contenu et les publicités, pour fournir des fonctionnalités de médias sociaux et pour analyser notre trafic. <br/>Nous partageons également des informations sur votre utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse.",
    cookiePolicy:"Politique relative aux cookies`,
    footerNavItems:["tac","faq","adhésion", "contact","language"],
    rights: "© 2025 NIHPLOD. TOUS LES DROITS SONT RÉSERVÉS.",
  }
  
}

const viewPolicy = () => {
  var loca = window.location.href
  if(loca.indexOf("tac") < 0){
    $(".cookie-policy").css('bottom','-25vh')
    checkCookieMobile()
  } else {
    checkPolicy("privacy-policy")
  }
}

const orignalSetItem = sessionStorage.setItem
  sessionStorage.setItem = function (key, newValue) {
    const setItemEvent = new Event("setItemEvent")
    setItemEvent.newValue = newValue
    window.dispatchEvent(setItemEvent)
    orignalSetItem.apply(this, arguments)
  }

class AppMobile extends Component  {
    componentWillMount(){
      if(JSON.parse(sessionStorage.getItem("cart")) != null){
        var currentlang = JSON.parse(sessionStorage.getItem("cart")).lang;
      } else {
        var currentlang = 'EN'
      }
      if(currentlang === "FR"){
        lang = 'FR'
      } else if(currentlang === "EN"){
        lang = 'EN'
      }
        checkCookieMobile()
  }

  componentDidMount(){
    window.history.scrollRestoration = 'manual'
  }
    
  render (){
    return (
      <div className="App AppMobile">
      <div className='slideMask'></div>
        <Router>
        {lang === 'EN' ?
        <div>
        <div className={`cookie-policy ${cookies.get('cookie') === "accepted" ? "accepted" : ""}`}>
            <div class="cookie-policy-description"><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.EN.cookieTip) }}></span><Link to={{pathname: "/tac" ,anchor: 'privacy-policy'}} className="cookie-policy-content" onClick={() => {viewPolicy()}}><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.EN.cookiePolicy) }}></span></Link>.</div><svg title="Accepter" className='cancel-icon' width="10" height="9" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {acceptMobile()}}><path d="M8.5 1.25 5 4.75l3.5 3.5M1 1.25l3.5 3.5L1 8.25"></path></svg>
          </div>
        <CartMobile></CartMobile>
        <div className="top-container">
          <div className='slideMask2'></div>
          <div className='top-mask'>
          <Header></Header>
            {routes.map(({ path, Component  }) => (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    appear={true}
                    timeout={{
                      appear: 1500,
                      enter: 1900,
                      exit: 1400,
                    }}
                    classNames={"page"}
                    unmountOnExit
                  >
                    <div className="page">
                      <Component />
                      <div className='footer'>
                        <div className='footer-divider'></div>
                        <div className='footer-nav'>
                          <Link to="/faq" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.EN.footerNavItems[1]}</Link>
                          <Link to="/tac" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.EN.footerNavItems[0]}</Link>
                          <Link to="/login" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.EN.footerNavItems[2]}</Link>
                          <Link to="/contact" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.EN.footerNavItems[3]}</Link>
                        </div>
                        <a href="https://www.instagram.com/nihplodskincare/" target="_blank" className="inst-icon"><img src={inst} alt="ins"></img></a>
                        <div className='rights'>
                          {pageText.EN.rights}
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}
          </div>
          </div>
      </div>
        :
        <div>
        <div className={`cookie-policy ${cookies.get('cookie') === "accepted" ? "accepted" : ""}`}>
            <div class="cookie-policy-description"><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.FR.cookieTip) }}></span><Link to={{pathname: "/tac" ,anchor: 'privacy-policy'}} className="cookie-policy-content" onClick={() => {viewPolicy()}}><span dangerouslySetInnerHTML={{ __html: upperLaundryCookie(pageText.FR.cookiePolicy) }}></span></Link>.</div><svg title="Accepter" className='cancel-icon' width="10" height="9" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {acceptMobile()}}><path d="M8.5 1.25 5 4.75l3.5 3.5M1 1.25l3.5 3.5L1 8.25"></path></svg>
          </div>
        <CartMobileFR></CartMobileFR>
        <div className="top-container">
          <div className='slideMask2'></div>
          <div className='top-mask'>
          <HeaderMobileFR></HeaderMobileFR>
            {routesFR.map(({ path, Component  }) => (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    appear={true}
                    timeout={{
                      appear: 1500,
                      enter: 1900,
                      exit: 1400,
                    }}
                    classNames={"page"}
                    unmountOnExit
                  >
                    <div className="page">
                      <Component />
                      <div className='footer'>
                        <div className='footer-divider'></div>
                        <div className='footer-nav'>
                          <Link to="/faq" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.FR.footerNavItems[1]}</Link>
                          <Link to="/tac" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.FR.footerNavItems[0]}</Link>
                          <Link to="/login" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.FR.footerNavItems[2]}</Link>
                          <Link to="/contact" className="footer-nav-item" onClick={()=>{changeUrlMobile()}}>{pageText.FR.footerNavItems[3]}</Link>
                        </div>
                        <a href="https://www.instagram.com/nihplodskincare/" target="_blank" className="inst-icon"><img src={inst} alt="ins"></img></a>
                        <div className='rights'>
                          {pageText.FR.rights}
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}
          </div>
          </div>
      </div>
        }
    </Router>
      </div>
    );
  }
}

export default AppMobile;
